import React, { useState, useEffect } from 'react';
import { Collapse, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import './Header.css';
import { MenuList } from './MenuList';

import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';
import MenuItems from './MenuItems';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from 'react-router-dom';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useForm } from "react-hook-form";
import InputGroup from 'react-bootstrap/InputGroup';
import { Helmet } from 'react-helmet';
import { TfiAngleRight } from 'react-icons/tfi';

import Aos from 'aos';
import { motion } from 'framer-motion';
import { navVariants } from '../common/motion/motion';

axios.defaults.headers.common['token'] = 'bcg123';

let call = '';

// getAllService/9154c83946c54cb457dc3eed118eab3a7de3a2e9

const Header = () => {


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register1, handleSubmit: handleSubmit1, watch: watch1, reset: reset1, formState: { errors: errors1 } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, watch: watch2, reset: reset2, formState: { errors: errors2 } } = useForm();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setServiceErr("");
    setsubServiceErr("");
  };
  const handleShow = () => setShow(true);
  const [services, setServices] = useState([]);
  const [submenus, setSubmenus] = useState([]);
  const [myspinner, setMyspinner] = useState(false);
  const [logo, setLogo] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [subServiceName, setSubServiceName] = useState('');
  const [subServiceId, setSubServiceId] = useState('');
  const [serviceErr, setServiceErr] = useState('');
  const [subServiceErr, setsubServiceErr] = useState('');
  const [gScript, setGScript] = useState([]);
  // 
  const [showLogin, setShowLogin] = useState(false);
  const handleShowLogin = () => setShowLogin(true);
  const [user, setuser] = useState("");
  const [pass, setpass] = useState("");
  const [passShow, setPassShow] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState("");
  const [passShowReg, setPassShowReg] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [showModel, setShowModel] = useState(false);
  const [mySpinnerReg, setMySpinnerReg] = useState(true);
  const [mySpinner, setMySpinner] = useState(true);
  const [showForgot, setShowForgot] = useState(false);

  const [forgetPassEmail, setForgetPassEmail] = useState('');
  const [forgetPass, setForgetPass] = useState(false);
  const [fpassErr, setFpassErr] = useState('')
  const [token, setToken] = useState('')
  const [changePass, setChangePass] = useState('');
  const [showChangePass, setShowChangePass] = useState(false);


  // user exist
  const [showExistModel, setShowExistModel] = useState(false);
  const handleShowExist = () => {
    setShowExistModel(true);
  }
  const handleCloseExist = () => {
    setShowExistModel(false);
  }

  const handleCloseLogin = () => {
    setShowLogin(false);
    setPassShow(false);
    setPassShowReg(false);
    setShowConfirmPass(false);
    reset({
      email: "",
      pass: "",
    });
    reset1({
      name: "",
      email: "",
      phone: "",
      password: "",
      confirmPass: ""
    })
  };

  const handleCloseForgot = () => setShowForgot(false);
  const handleShowForgot = () => {
    setShowForgot(true);
    handleCloseLogin();
    setShowChangePass();
    reset2({
      email: "",
      token: "",
      password: ""
    })
  };

  let LogoImg = logo?.map((logo) => {
    call = logo.phone
    return logo.logo
  });


  const navigate = useNavigate();

  const getServices = () => {

    // getGlobal

    axios.get('/api/getGlobal').then((response) => {
      setGScript(response.data.data);
      console.log(response.data.data);
    });

    axios.get('/api/getLogo').then((response) => {
      setLogo(response.data.data);
      console.log(response.data.data);
    });
    // getServiceHeader 
    axios.get('/api/getallsubservice').then((response) => {
      setSubmenus(response.data.service);
      setServices(response.data.service);

    });
  }

  useEffect(() => {
    getServices();
    Aos.init({
      duration: 1000,
    })
  }, []);

  var gs = gScript?.map((gs) => { return gs.global_script }).toString();

  const handleServices = (e) => {
    let id = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const additionalData = selectedOption.dataset.additionalData;
    setServiceId(id);
    setServiceName(additionalData.replace(/\s+/g, '-'));
    if (id != "") {
      setMyspinner(true);
      axios.get(`/api/getSubServiceById/${id}`
      ).then((response) => {
        setMyspinner(false);
        setSubservices(response.data.data);
      });
    } else {
      setSubservices([]);
    }

    // let id = e.target.value
    // setServiceId(id);

    // axios.get(`/api/getSubServiceById/${id}`
    // ).then((response) => {
    //   setSubservices(response.data.data);
    // });
  }

  const handleSubServices = (event) => {
   
    const selectedOption = event.target.options[event.target.selectedIndex];
    const additionalData = selectedOption.dataset.additionalData;
   

    setSubServiceId(event.target.value);
    setSubServiceName(additionalData.replace(/\s+/g, '-'));
  }

  const [clicked, setClicked] = useState(false);
  const [handleDrop, setHandleDrop] = useState(false);

  const handleClicked = () => {
    setClicked(!clicked);
    setHandleDrop(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setHandleDrop(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [handleDrop]);

  const menuList = MenuList?.map((item, index) => {

    return (
      <>
        {item.title == 'Services' ?
          <li className="nav-item dropdown" key={index} >
            <span style={{ cursor: 'pointer' }} onClick={() => setHandleDrop(!handleDrop)}
              className={window.location.hash.includes("#/services") ? "active" : null}
            >
              {item.title}
              {/* <FaAngleDown className='drop_toggle' /> */}
            </span>

            <ul className={handleDrop ? "dropdown-menu main_menu" : "dropdown-menu main_menu d-none"}>
              {submenus?.map((service) => {
                return (
                  // <NavLink key={service.id} to={`services/${service.id}`} onClick={() => handleClicked()}>{service.name}</NavLink>
                  <li key={service.id}>
                    <div className='submenuDiv'>
                      <NavLink className='submenuLink' to={`services/${service.id}/${service.name.replace(/\s+/g, '-')}`}
                        onClick={() => setClicked(false)}
                      >{service.name}</NavLink> <TfiAngleRight className='arrow_icn' />
                      {/* <BiChevronsRight className='rotateArrow' /> */}
                    </div>
                    <ul className={handleDrop ? "dropdown-menu dropdown-submenu" : "dropdown-menu dropdown-submenu d-none"} >
                      <MenuItems serviceId={service.id} serviceName={service.name} submenu={service.subservice} handleClick={handleClicked} />
                    </ul>
                  </li>
                )
              })}
            </ul>
          </li>
          :
          <li >
            <NavLink end to={item.url} activeclassname="active" onClick={() => setClicked(false)} >{item.title}</NavLink>
          </li>
        }

      </>

      // <MenuItems key={index} item={item} submenu={submenus} clicked={() => setClicked(false)} />
    );
  });

  const validate = () => {
    if (serviceId == "") {
      setServiceErr("Please Select Service-Type");
    } else if (subServiceId == "") {
      setServiceErr("")
      setsubServiceErr("Please Select Sub-Service-Type")
    } else {
      setServiceErr("")
      setsubServiceErr("")
      navigate(`services/${serviceId}/${serviceName.replace(/\s+/g, '-')}/${subServiceId}/${subServiceName.replace(/\s+/g, '-')}`);
      setSubservices([]);
      handleClose();
    }
  }

  const notifyRegister = () => toast.success('Registration Successful', { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const warningMsgRegister = (err) => toast.error(`${err}`, { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const notify = () => toast.success('Login Successful', { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const warningMsg = (error) => toast.error(`${error}`, { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const notifyToken = () => toast.success('Token sent Successfully', { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
  const warningEmail = (error) => toast.error(`${error}`, { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const notifyResetPass = () => toast.success('Reset Password Successfully Please Login...', { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const warningResePass = (error) => toast.error(`${error}`, { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const notifyActive = (msg) => toast.success(`${msg}`, { autoClose: 3000, position: toast.POSITION.TOP_CENTER });
  const warningActive = (err) => toast.error(`${err}`, { autoClose: 3000, position: toast.POSITION.TOP_CENTER });

  // sign up & login 
  // Sign Up 
  async function signUp(data) {

    setMySpinnerReg(false)

    let result = await axios.get("/api/insertUser", {
      name: username,
      email: email,
      phone: phone,
      password: password,
      address: '',
      postcode: ''
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(response => {
      if (response.status === 200) {
        setShowModel(true);
        notifyRegister();
        setMySpinnerReg(true);
        console.log(response.data);
        localStorage.setItem('auth1', email);
        localStorage.setItem('userId', response.data.userID);
        handleCloseLogin();
        setTimeout(function () {
          window.location.reload(1);
        }, 2000);
      }
    })
      .catch(err => {
        if (err.response.data.message === "Account is De-activated..") {
          handleCloseLogin();
          handleShowExist();
        } else {
          warningMsgRegister(err.response.data.message);
          setMySpinnerReg(true);
        }

      })
    setMySpinnerReg(true)
  }

  // login
  async function logIn() {
    setMySpinner(false);

    await axios.get('/api/userLogin', {
      email: user,
      password: pass
    }, {
      // auth: {
      //   username: user,
      //   password: pass
      // },
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(response => {
      if (response.status === 200) {
        notify();
        localStorage.setItem('auth1', user);
        localStorage.setItem('userId', response.data.userID);
        navigate('/');
        setMySpinner(true);
        handleCloseLogin();
        setTimeout(function () {
          window.location.reload(1);
        }, 2000);
      }

    }).catch(errr => {
      if (errr) {
        warningMsg(errr.response.data.message);
      }
      setMySpinner(true);
    })

  }


  const handleForgot = () => {
    // setMySpinner(false);

    axios.get('/api/forgetPass', {
      email: forgetPassEmail,

    }, {

      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(response => {
      if (response.status === 200) {
        console.log(response.data)
        setForgetPass(true);
        notifyToken();
      }
    })
      .catch(err => {
        console.log(err);
        warningEmail(err.response.data.message);
      })
  }

  const resetPassword = () => {
    // setMySpinner(false);

    axios.get('/api/resetPass', {
      token: token,
      email: forgetPassEmail,
      password: changePass
    }, {

      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(response => {
      if (response.status === 200) {
        notifyResetPass();
        setTimeout(function () {
          window.location.reload(1);
        }, 2000);
      }
    })
      .catch(err => {
        warningResePass(err.response.data.message);
        console.log(err);
      })
  }


  const handleActive = () => {
    axios.get('/api/activateUser', {
      email: email,
    }, {

      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(response => {
      if (response.status === 200) {

        handleCloseExist();
        notifyActive(response.data.message);
        setTimeout(function () {
          window.location.reload(1);
        }, 2000);
      }
    })
      .catch(err => {
        warningActive(err.response.data.message);
        handleCloseExist();
        console.log(err);
      })
  }


  const logOut = () => {
    localStorage.setItem("auth1", "");
    localStorage.setItem("userId", "");
    navigate('/');
    setTimeout(function () {
      window.location.reload(1);
    }, 1000);
  }

  return (
    <>


      <Helmet>

        <script>{gs}</script>
      </Helmet>

      {/* navbar */}
      <div className='container-fluid navv' >
        <motion.nav className='navbar'
          variants={navVariants}
          initial="hidden"
          whileInView="show"
        >
          <div className='logo' >
            <Link data-aos="fade-zoom-in" to='/' className='d-flex'><img src={`/api/assets/logo/${LogoImg}`} alt="site-logo" width={250} /></Link>
          </div>

          <div className='navLinks_div'>
            <div className='menu-icon'>
              <span
                onClick={handleClicked}
              >
                {clicked ? <FaTimes className='iconColor' /> : <FaBars className='iconColor' />}
              </span>
            </div>
            <ul className={clicked ? 'showLinks' : 'hideLinks'}>
              {menuList}

              {localStorage.getItem('auth1') ?

                <li className="nav-item dropdown">
                  <span style={{ cursor: 'pointer' }} onClick={() => setHandleDrop(!handleDrop)}
                    className={window.location.hash.includes("#/profile") || window.location.hash.includes("#/my-bookings") || window.location.hash.includes("#/booking") ? "active" : null}
                  >
                    My Account
                    {/* <FaAngleDown className='drop_toggle' /> */}
                  </span>

                  <ul style={{ width: "auto" }} className={handleDrop ? "dropdown-menu main_menu" : "dropdown-menu main_menu d-none"}>

                    <li>
                      <NavLink end to='/profile' activeclassname="active" onClick={() => setClicked(false)} >Profile</NavLink>
                    </li>
                    <li>
                      <NavLink end to='/my-bookings' activeclassname="active" className={window.location.hash.includes("#/booking") ? 'active' : null} onClick={() => setClicked(false)} >My Bookings</NavLink>
                    </li>
                    <li>
                      <span style={{ cursor: "pointer" }} onClick={logOut} >Logout</span>
                    </li>

                  </ul>
                </li>

                // <li>

                //   <NavLink end to='/profile' activeclassname="active" onClick={() => setClicked(false)} >My Account</NavLink>
                // </li>

                : <li ><Link data-aos="fade-zoom-in" to="#" onClick={handleShowLogin}><FaUserCircle fontSize={'20px'} /> Login</Link></li>}

              <li ><Button className='appoinment-btn' data-aos="fade-zoom-in"
                onClick={handleShow}
              >
                {/* <img src={bookAppoinment} style={{ width: '28px' }} /> */}
                Book Now</Button></li>
            </ul>
          </div>

        </motion.nav>
      </div>

      <hr className='m-0'></hr>

      {/* signup login model */}
      <Modal
        show={showLogin}
        onHide={handleCloseLogin}
        backdrop="static"
        keyboard={false}
        className="login_m"

      >

        <Modal.Header closeButton className='m_head'></Modal.Header>
        <Modal.Body className='m_body p-0'>
          <Tabs
            defaultActiveKey="login"
            id="fill-tab-example"
            className="mb-4"
            fill
          >
            <Tab eventKey="login" title="Login">

              <Form onSubmit={handleSubmit(logIn)} autoComplete="off">
                <Form.Group >
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="text" placeholder="Please Enter Email" value={user}
                    {...register('email', {
                      required: true, maxLength: 30,
                      onChange: (e) => setuser(e.target.value),
                    })}
                  />
                  {errors.email && errors.email.type === "required" && <span className='err_msg'>Please Enter Your Email</span>}
                </Form.Group>

                <Form.Group>
                  <div className="form-group" style={{ position: 'relative' }}>
                    <label className='form-label'>Password</label>
                    <input type={passShow ? "text" : "password"} autoComplete="new-password"
                      {...register('pass', {
                        required: true, maxLength: 30,
                        onChange: (e) => setpass(e.target.value),
                      })}
                      className="form-control" placeholder="Please Enter Password" />
                    <Button className='eyeButton' onClick={() => setPassShow(!passShow)}>{passShow ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>

                    <span className='f_pass' style={{ float: 'right', cursor: 'pointer', }}
                      onClick={handleShowForgot}
                    >
                      Forget Password ?</span>
                  </div>
                  {errors.pass && errors.pass.type === "required" && <span className='err_msg'>Please Enter Password</span>}

                </Form.Group>

                <div style={{ textAlign: 'center', margin: '30px 0px' }}>
                  <Button className='loginBtn' type='submit'
                    data-dismiss={showModel ? "modal" : ""}
                  >
                    <div className={mySpinner ? "d-none" : "spinner-border spinner-border-sm login_spinner"} role="status"></div>
                    Login</Button>
                </div>
              </Form>
            </Tab>

            <Tab eventKey="register" title="Register">
              <Form onSubmit={handleSubmit1(signUp)} autoComplete="off" >
                <Form.Group >
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Please Enter Your Name" value={username}
                    {...register1('name', {
                      required: true, maxLength: 30,
                      onChange: (e) => setUsername(e.target.value),
                    })}
                  />
                  {errors1.name && errors1.name.type === "required" && <span className='err_msg'>Please Enter Your Name</span>}
                </Form.Group>

                <Form.Group >
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="Please Enter Your Email Address" value={email}
                    {...register1('email', {
                      required: true, maxLength: 30,
                      onChange: (e) => setEmail(e.target.value),
                    })}
                  />
                  {errors1.email && errors1.email.type === "required" && <span className='err_msg'>Please Enter Your Email</span>}
                </Form.Group>
                <Form.Group >
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="number" placeholder="Please Enter Your Phone Number" value={phone}
                    {...register1('phone', {
                      required: true, maxLength: 15, minLength: 10,
                      onChange: (e) => setPhone(e.target.value),
                    })}
                  />
                  {errors1.phone && errors1.phone.type === "maxLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                  {errors1.phone && errors1.phone.type === "minLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                  {errors1.phone && errors1.phone.type === "required" && <span className='err_msg'>Please Enter Your Phone Number</span>}
                </Form.Group>
                <Form.Group >
                  <div className="form-group" style={{ position: 'relative' }}>
                    <label className='form-label'>Password</label>
                    <input type={passShowReg ? "text" : "password"} value={password} autoComplete="new-password"
                      {...register1('password', {
                        required: true, maxLength: 30,
                        onChange: (e) => setPassword(e.target.value),
                      })}
                      className="form-control" placeholder="Please Enter Password" />
                    <Button className='eyeButton' onClick={() => setPassShowReg(!passShowReg)}>{passShowReg ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>
                    {/* <span className='error_msg'>{passRegErr}</span> */}
                  </div>

                  {errors1.password && errors1.password.type === "required" && <span className='err_msg'>Please Enter Password</span>}
                </Form.Group>
                <Form.Group >
                  <div className="form-group" style={{ position: 'relative' }}>
                    <label className='form-label'>Confirm Password</label>
                    <input type={showConfirmPass ? "text" : "password"} value={confirmPassword}
                      {...register1('confirmPass', {
                        required: "Please Confirm Password", maxLength: 30,
                        message: 'Your passwords do no match',
                        onChange: (e) => setConfirmPassword(e.target.value),
                        validate: (confirmPassword) => {
                          if (watch1('password') != confirmPassword) {
                            return "Your passwords do no match";
                          }
                        },
                      })}
                      className="form-control" placeholder="Please Enter Password Again" />
                    <Button className='eyeButton' onClick={() => setShowConfirmPass(!showConfirmPass)}>{showConfirmPass ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>

                  </div>

                  {errors1.confirmPass && <span className='err_msg'>{errors1.confirmPass.message}</span>}
                </Form.Group>

                <div style={{ textAlign: 'center', margin: '30px 0px' }}>
                  <Button className='loginBtn' type='submit'>
                    <div className={mySpinnerReg ? "d-none" : "spinner-border spinner-border-sm login_spinner"} style={{ top: '62%', left: '37%' }} role="status"></div>
                    Signup
                  </Button>
                </div>
              </Form>
            </Tab>
          </Tabs>
        </Modal.Body>

      </Modal>

      {/* forgot pass */}

      <Modal
        show={showForgot}
        onHide={handleCloseForgot}
        className="login_m"
      >
        <Modal.Header closeButton className='m_head'></Modal.Header>
        <Modal.Body className='m_body p-0'>
          <Form onSubmit={handleSubmit2(resetPassword)} style={{ padding: '40px' }}>

            <Form.Group >
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" placeholder="Please Enter Your Email Address" value={forgetPassEmail} disabled={forgetPass ? true : false}
                {...register2('email', {
                  required: true, maxLength: 30,
                  onChange: (e) => setForgetPassEmail(e.target.value),
                })}
              />
              {errors2.email && errors2.email.type === "required" && <span className='err_msg'>Please Enter Your Email</span>}
            </Form.Group>

            <div style={forgetPass ? { display: 'block' } : { display: 'none' }}>
              <Form.Group >
                <Form.Label>Token</Form.Label>
                <Form.Control type="text" placeholder="Please Enter Token"
                  {...register2('token', {
                    required: true, maxLength: 30,
                    onChange: (e) => setToken(e.target.value),
                  })}
                />
                {errors2.token && errors2.token.type === "required" && <span className='err_msg'>Please Enter Token</span>}
              </Form.Group>
              <Form.Group >
                <div className="form-group" style={{ position: 'relative' }}>
                  <label>New Password</label>
                  <input type={showChangePass ? "text" : "password"} value={changePass}
                    {...register2('password', {
                      required: true, maxLength: 30,
                      onChange: (e) => setChangePass(e.target.value),
                    })}
                    className="form-control" placeholder="Please Enter Password" />
                  <Button className='eyeButton' onClick={() => setShowChangePass(!showChangePass)}>{showChangePass ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>
                </div>

                {errors2.password && errors2.password.type === "required" && <span className='err_msg'>Please Enter New Password</span>}
              </Form.Group>
            </div>
            <div>
              {forgetPass ?
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <button className='loginBtn' type='submit'>Reset Password</button>
                </div>
                :
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <button className='loginBtn' onClick={handleForgot}>Submit</button>
                </div>
              }

            </div>

          </Form>
        </Modal.Body>

      </Modal>





      {/* model book */}

      <Modal show={show} onHide={handleClose}>
        <div className={myspinner ? 'spinner_d' : 'd-none'}>
          <Spinner animation="border" className='mySpinner' />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Book Our Service</Modal.Title>
        </Modal.Header>
        <Modal.Body style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>

          <div className='book_appointment-m'>
            <div>
              <Form.Select aria-label="SELECT SERVICE TYPE" onChange={handleServices}>
                <option value="">SELECT SERVICE TYPE</option>
                {services?.map((item) => {
                  return (
                    <option key={item.id} value={item.id} label={item.name} data-additional-data={item.name}>{item.name}</option>
                  )
                })}
              </Form.Select>
              <span className='err_msg'>{serviceErr}</span>
            </div>


            <div>
              <Form.Select aria-label="SELECT SUB SERVICE TYPE" 
                onChange={handleSubServices}
              >
                <option>SELECT SUB SERVICE TYPE</option>
                {subservices?.map((subservice) => {
                  return (
                    <option key={subservice.id} value={subservice.id} data-additional-data={subservice.name} >{subservice.name}</option>
                  )
                })}
              </Form.Select>
              <span className='err_msg'>{subServiceErr}</span>
            </div>


            <Button className='appointment_btn_yellow'
              onClick={validate}
            >
              {/* <img style={{ width: '30px' }} src={bookAppoinment2} />  */}
              Book Appointment</Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showExistModel} onHide={handleCloseExist}>
        <div className={myspinner ? 'spinner_d' : 'd-none'}>
          <Spinner animation="border" className='mySpinner' />
        </div>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className='exist_model' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
          <h3>
            Your account is De-activate,<br></br>
            Do you want to Re-active your account?
          </h3>

        </Modal.Body>
        {/* <Modal.Footer> */}
        <div className='exist_btn'>
          <Button className='_cancel' onClick={handleCloseExist}>No, Don't</Button>
          <Button className='loginBtn' onClick={handleActive}>Yes, Active</Button>
        </div>
        {/* </Modal.Footer> */}
      </Modal>


    </>
  )
}

export default Header
export { call }



// forgetPass resetPass
