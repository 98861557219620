
import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import DataTables from '../DataTable/DataTables';
import { AiFillCodepenSquare } from 'react-icons/ai';
import { MdMarkEmailUnread } from 'react-icons/md';
import { BsFillPersonLinesFill, BsCreditCard } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import { GiVacuumCleaner } from 'react-icons/gi';
import { ToastContainer, toast } from "react-toastify";
import { FaEye, FaUsers } from 'react-icons/fa';


const Dashboard = () => {

    const [pending, setPending] = useState(true);
    const [bookingData, setBookingData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [enqData, setEnqData] = useState([]);
    const [bookings, setBookings] = useState('');
    const [enquiries, setEnquiries] = useState('');
    const [services, setServices] = useState('')
    const [customers, setCustomers] = useState('');
    // booking
    const [showBooking, setShowBooking] = useState(false);
    const [bookingId, setBookingId] = useState('');
    const [bookingStatus, setBookingStatus] = useState('');
    // cancel
    const [show, setShow] = useState(false);
    const [cancelId, setCancelId] = useState('');

    const getData = () => {

        axios.get('/api/getCount').then((response) => {
            setBookings(response.data.Booking);
            setEnquiries(response.data.Enquiry);
            setServices(response.data.SubService);
            setCustomers(response.data.ActiveCustomer);
        }).catch((err) => {
            console.log(err)
        })

        axios.get('/api/getBookingAddress').then((response) => {
            setBookingData(response.data.data);
            setNewData(response.data.data)
            // setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            console.log(err)
        })
        axios.get('/api/getEnquiry').then((response) => {
            setEnqData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err)
        })
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        setBookingData(newData?.filter(data => data.booking_status == 0));

    }, [newData]);

    const handleBookingClose = () => {
        setShowBooking(false);
        setBookingId('');
        setBookingStatus('');
    }

    const handleShowBooking = (e) => {

        let id = e.target.id;
        let status = e.target.value;
        if (status == 3) {
            setShow(true);
            setCancelId(id);

        } else {
            setShowBooking(true);
            setBookingId(id);
            setBookingStatus(status);
        }

    }

    const handleClose = () => {
        setShow(false);
    };


    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });

    // bookingStatus
    const handleStatus = () => {
        let id = bookingId;
        let status = bookingStatus;
        axios.get('/api/bookingStatus', {
            id: id,
            booking_status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                handleBookingClose();
                window.location.reload(false);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const cancelSuccess = () =>
        toast.info("Booking Canceled Successfully", { position: "top-center" });

    // booking cancel 
    const handleCancel = () => {
        axios.get('/api/bookingCancel', {
            id: cancelId,
            cancel_by: localStorage.getItem("adminType") == 0 ? 1 : 2
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                cancelSuccess();
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            }

        }).catch(err => {
            console.log(err)
        })
    }




    const tableData = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },
            {
                name: "Booking Id",
                selector: "booking_id",
                // width: '200px',
                sortable: true
            },

            {
                name: "Payment Type",
                selector: "payment_type",
                sortable: true
            },


            {
                name: "Address",
                selector: "address",
                // width: '200px',
                sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
            {
                name: "Booking Status",
                width: '250px',
                selector: row => <select className=" col-md-4 select_status" style={row.booking_status == 2 ? { backgroundColor: 'green' } : row.booking_status == 3 ? { backgroundColor: 'gray' } : null} disabled={row.booking_status == 2 || row.booking_status == 3 || row.cancel_status == 2 || localStorage.getItem("editAuth") == 1 ? true : false}
                    value={row.booking_status}
                    id={row.book_id}
                    onChange={(e) => handleShowBooking(e)}
                >
                    <option value="0" disabled={row.booking_status >= 0 ? true : false}>Pending</option>
                    <option value="1" disabled={row.booking_status >= 1 ? true : false}>Confirm</option>
                    <option value="2" disabled={row.booking_status == 2 ? true : false}>Completed</option>
                    <option value="3" disabled={row.booking_status == 3 ? true : false}>Cancelled</option>

                </select>,
                center: true,

            },

            {
                name: "Action",
                cell: row => <Link to={`/admin/bookings/bookingsummary/${row.book_id}`}><Button className='_view_icons' ><FaEye /></Button></Link>,
                right: true

                // cell: row => <div><Button>Click</Button><Button>Update</Button></div>
                // selector: <Button>Click</Button>,

            },

        ],

    };


    const enqTable = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: "name",
                // width: '200px',
                sortable: true
            },
            {
                name: "Email",
                selector: "email",
                // width: '200px',
                sortable: true
            },
            {
                name: "Phone",
                selector: "phone",
                // width: '200px',
                sortable: true
            },
            {
                name: "Address",
                selector: "address",
                // width: '200px',
                sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
            {
                name: "Message",
                selector: "message",
                // width: '100px',
                sortable: true
            },

        ],

    };


    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | Dashboard</title>

            </Helmet>


            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>


            <section id="main-content" style={pending ? { opacity: "0.5" } : { opacity: "1" }} >
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Dashboard</span>

                            <section>
                                <div className='dashboard_card m-4'>

                                    <Link to="/admin/bookings">
                                        <Card style={{ width: '14rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{bookings}</h1>
                                                    <Card.Title>Bookings</Card.Title>
                                                    <h6>(Pending)</h6>
                                                </div>
                                                <BsFillPersonLinesFill className='c_icon' />
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    <Link to="/admin/enquiries">
                                        <Card style={{ width: '14rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{enquiries}</h1>
                                                    <Card.Title>Enquiries</Card.Title>
                                                    <h6>(Pending)</h6>
                                                </div>
                                                <MdMarkEmailUnread className='c_icon' />

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    <Link to="/admin/subservice">
                                        <Card style={{ width: '14rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{services}</h1>
                                                    <Card.Title>Sub Services</Card.Title>
                                                    <h6>(Active)</h6>
                                                </div>
                                                <GiVacuumCleaner className='c_icon' />
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    <Link to="/admin/customers">
                                        <Card style={{ width: '14rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{customers}</h1>
                                                    <Card.Title>Customers</Card.Title>
                                                    <h6>(Active)</h6>
                                                </div>
                                                <FaUsers className='c_icon' />
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>

                            </section>

                            <section class="card m-3">

                                <div className='main'>
                                    <Link to='/admin/bookings'><Button className='Add_btn_'>
                                        View All
                                    </Button></Link>
                                    <DataTables data={bookingData?.slice(0, 5)} title="Pending Bookings" columns={tableData.columns} pending={pending} />

                                </div>
                            </section>
                            <section class="card m-3 mt-5">
                                <div className='main'>
                                    <Link to='/admin/enquiries'><Button className='Add_btn_'>
                                        View All
                                    </Button></Link>
                                    <DataTables data={enqData?.slice(0, 5)} title="Enquiries" columns={enqTable.columns} pending={pending} />

                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>

            <Modal show={showBooking} onHide={handleBookingClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Booking Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure to {bookingStatus == 2 ? "Complete" : "confirm"} this Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleBookingClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button className='Add_btn_' onClick={() => handleStatus()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Booking Cancel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure Do You want to Cancel this Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button variant='danger' onClick={() => handleCancel()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>


        </>
    )
}

export default Dashboard
