import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import "react-data-table-component-extensions/dist/index.css";
import Modal from "react-bootstrap/Modal";
import { HiRefresh } from "react-icons/hi";
import { FaShare, FaEdit, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { Button, Dropdown } from "react-bootstrap";
import DataTables from "../DataTable/DataTables";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";

const Banner = () => {
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [file, setFile] = useState("");
  const [content, setContent] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    reset({
      name: "",
      content: "",
      banner: "",
    });
  };
  const handleShow = () => setShow(true);
  const [pending, setPending] = useState(true);

  const getData = () => {
    axios.get("/api/getAdminBanner").then((response) => {
      setData(response.data.data);
      setPending(false);
      console.log(response.data.data);
    });

    // axios.get(`/api/getBanner/${id}`
    // ).then((response) => {
    //     setBanner(response.data.data);
    //   console.log(response.data.data);

    // });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleBanner = (id) => {
    setId(id);
    handleShow();
  };

  useEffect(() => {
    data
      ?.filter((data) => data.id == id)
      ?.map((data) => {
        setName(data.page);
        setBanner(data.image);
        setContent(data.content);
      });
  }, [id]);

  const updateSuccess = () =>
    toast.info("Banner Update Successfully", { position: "top-center" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(
        "/postupdateBanner",
        {
          id: id,
          page: name,
          content: content,
          image: file,

        },
        {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleClose();
          updateSuccess();
          window.location.reload(false);
        }
      });
  };


  function getBanner(page) {
    switch (page) {
      case '1':
        return (
          <><span>Home</span></>
        )

      case '2':
        return (
          <><span>About Us</span></>

        )
      case '3':
        return (
          <><span>Gallery</span></>

        )
      case '4':
        return (
          <><span>Blog</span></>

        )
      case '5':
        return (
          <><span>Single Blog</span></>

        )
      case '6':
        return (
          <><span>Contact Us</span></>

        )
    }

  }

  const tableData = {
    columns: [
      {
        name: "#",
        // width: '50px',
        cell: (row, index) => index + 1,
      },
      {
        name: "Page",
        cell: (row) => (
          <div>
            {getBanner(row.page)}
          </div>
        ),
        // width: '200px',
        sortable: true,
      },
      {
        name: "Image",
        selector: (row) => (
          <img
            style={{ width: "50px", height: "50px", padding: "10px" }}
            src={`/api/assets/banner/${row.image}`}
          />
        ),
        // width: '200px',
        sortable: true,
      },
      {
        name: "Content",
        selector: "content",
        // width: '200px',
        sortable: true,
        // cell: d => <span>{d.genres.join(", ")}</span>
      },
      {
        name: "Action",
        cell: (row) => (
          <div className="d-flex" style={{ gap: "10px" }}>
            {localStorage.getItem("editAuth") == 0 ?
              <Button
                className="_edit_icons"
                onClick={() => handleBanner(row.id)}
              >
                <FaEdit />
              </Button> : null}
          </div>
        ),
        right: true
      }

    ],
  };

  return (
    <>
      <Helmet>
        <title>Blue Cleaning Group | Banner</title>
      </Helmet>

      <Spinner
        animation="border"
        className={pending ? "mySpinner" : "d-none"}
      />
      <section
        id="main-content"
        style={pending ? { opacity: "0.5" } : { opacity: "1" }}
      >
        <section className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <span className="p-3">Banner</span>
              <section className="card m-3">

                <div className="main">
                  <DataTables
                    data={data}
                    columns={tableData.columns}
                    title="Banner"
                  />
                  {/* </DataTableExtensions> */}
                </div>
              </section>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton className="adminpass">
                <Modal.Title>Update Banner</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                    <Form.Label>Content</Form.Label>
                    <Form.Control
                      type="text"
                      value={content}
                      {...register("content", {
                        required: false,
                        onChange: (e) => setContent(e.target.value),
                      })}
                    />
                    {errors.content && (
                      <div className="errMsg">Please Enter Content</div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Banner</Form.Label>
                    <Form.Control
                      type="file"
                      {...register("banner", {
                        required: false,
                        onChange: (e) => setFile(e.target.files[0]),
                      })}
                    />
                    {errors.banner && (
                      <div className="errMsg">Please Select the Banner</div>
                    )}
                    <div className="text-center p-3">
                      {file == "" ? (
                        <img
                          src={`/api/assets/banner/${banner}`}
                          style={{ maxWidth: "300px", maxHeight: "300px" }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(file)}
                          style={{ maxWidth: "300px", maxHeight: "300px" }}
                        />
                      )}
                    </div>
                  </Form.Group>

                  <div className="_add_float mt-2">
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="_close_btn"
                    >
                      Close
                    </Button>
                    <Button className="Add_btn_" type="submit">Update</Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </section>
      </section>
    </>
  );
};

export default Banner;
