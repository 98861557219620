import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '@mui/material';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import axios from 'axios';
import { motion } from 'framer-motion';
import { fixedContainer, textVariants } from '../common/motion/motion';

const CommanProcess = ({ service }) => {




    const animatedComponents = makeAnimated();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [msg, setMsg] = useState('');



    const onSelect = (e) => {
        let subServiceId = e?.map((id => id.id)).toString();
        setServiceId(subServiceId)
    }

    const onRemove = (e) => {
        let subServiceId = e?.map((id => id.id)).toString();
        setServiceId(subServiceId)
    }

    const submitSuccess = () => toast.success("From Submit Successfully!",
        { position: "top-center", }
    );

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = () => {
        axios.get('/api/enquiry', { name: name, email: email, sub_service_id: service_id, message: msg, phone: phone, address: address }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                submitSuccess();
                setName('');
                setPhone('');
                setEmail('');
                setMsg('');
                reset({
                    name: "",
                    address: "",
                    email: "",
                    phone: "",
                    message: ""
                })
                window.scrollTo(0, 0);
                // setServiceId('')
            }

        })

    }

    let service_id = '';
    const selectedService = service?.map((service) => {

        service_id = service.id;
        // console.log(service);
        return (
            <option key={service.id} value={service.id} selected>{service.name}</option>
        )
    })


    return (
        <>
            <div className='container-fluid'>
                <ToastContainer />
                <section>
                    <motion.div
                        variants={textVariants('left', 0.1)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        className='comman_form _53Ji7'>
                        <Form className='row m-auto' style={{ width: '85%' }} onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form_div mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name"
                                    {...register('name', {
                                        required: true, maxLength: 50,
                                        onChange: (e) => setName(e.target.value),
                                    })}
                                />
                                {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Your Name</span>}
                            </Form.Group>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form_div mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="Enter Address"
                                    {...register('address', {
                                        required: true, maxLength: 50,
                                        onChange: (e) => setAddress(e.target.value),
                                    })}
                                />
                                {errors.address && errors.address.type === "required" && <span className='err_msg'>Please Enter Your Address</span>}
                            </Form.Group>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form_div mb-3">
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control type="email" placeholder="Enter email"
                                    {...register('email', {
                                        required: true, maxLength: 50,
                                        onChange: (e) => setEmail(e.target.value),
                                    })}
                                />
                                {errors.email && errors.email.type === "required" && <span className='err_msg'>Please Enter email Address</span>}
                            </Form.Group>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form_div mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number" placeholder="Enter Phone Number" maxLength={15}
                                    {...register('phone', {
                                        required: true, maxLength: 15, minLength: 10,
                                        // pattern: {
                                        //     value: /^[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{5,10}$/,
                                        //     message: "Minimum 10 digit required"
                                        // },
                                        onChange: (e) => setPhone(e.target.value),
                                    })}
                                    value={phone}
                                />
                                {errors.phone && errors.phone.type === "maxLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                               {errors.phone && errors.phone.type === "minLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                               {errors.phone && errors.phone.type === "required" && <span className='err_msg'>Please Enter Your Phone Number</span>}
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form_div mb-3">
                                <Form.Label>Services</Form.Label>
                                <Form.Select disabled  >

                                    {selectedService}
                                </Form.Select>
                                
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 form_div mb-3">
                                <Form.Label>Requirements</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                    value={msg}
                                    {...register('message', {
                                        required: true,
                                        onChange: (e) => setMsg(e.target.value)
                                    })}
                                />
                                {errors.message && errors.message.type === "required" && <span className='err_msg'>Please Enter Your Message</span>}
                            </Form.Group>
                            <div className=''>
                                <motion.Button
                                    variants={textVariants('up', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    className='btn appointment_btn_yellow m-auto'
                                    type='submit'
                                //  onClick={}
                                ><span>Get A Quote</span></motion.Button>
                            </div>
                        </Form>
                    </motion.div>
                </section>
            </div>
        </>
    )
}

export default CommanProcess


