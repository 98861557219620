import React,{useState} from 'react';
import {BsFillCheckCircleFill} from 'react-icons/bs';
import { Form } from 'react-bootstrap';

const ExtraServices = ({id, name, title, image, handleChecked, countedData}) => {

    let count = countedData?.filter(data => data.id == id)?.map(data => data.count).toString();
    let countValue = count? true : false

    // const [checked, setChecked] = useState(countValue);

    return (
        <>
            <div className='extras_d'>

                <Form.Control type="checkbox" id={id} title={title} name={name} style={{ backgroundImage: `url(assets/extras/${image})` }}
                    onChange={(e) => handleChecked(e)}
                    checked={countValue} 
                />
                <label htmlFor={id}><span>{title}</span><BsFillCheckCircleFill className="check_icon" /></label>



            </div>
        </>
    )
}

export default ExtraServices