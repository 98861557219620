import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DataTables from "../DataTable/DataTables";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

const GalleryAdmin = () => {
  const [data, setData] = useState([]);
  const [services, setServices] = useState([]);
  const [pending, setPending] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [file, setFile] = useState("");
  const [serviceId, setServiceId] = useState("");
  const handleClose = () => {
    setShow(false);
    setFile("");
    reset({
      service: "",
      image: "",
    });
  };

  const getData = () => {
    axios.get("/api/allGallery").then((response) => {
      setData(response.data.data);
      setPending(false);
      console.log(response.data.data);
    });

    axios
      .get("/getServiceHeader")
      .then((response) => {
        setServices(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addSuccess = () =>
    toast.info("Galley Added Successfully", { position: "top-center" });
  const deleteSuccess = () =>
    toast.info("Galley Deleted Successfully", { position: "top-center" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {

    const formData = new FormData();
    formData.append("image", file);

    axios
      .post(
        "/addGallery",
        {
          service_id: serviceId,
          image: file,
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          addSuccess();
          handleClose();
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleDelete = (id) => {
    axios
      .post(
        "/deleteGallery",
        {
          id: id
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          deleteSuccess();
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function getService(page) {
    switch (page) {
      case '1':
        return (
          <><span>Residential Service</span></>
        )

      case '2':
        return (
          <><span>Construction Service</span></>

        )
      case '3':
        return (
          <><span>Commercial Service</span></>

        )
    }

  }





  const tableData = {
    columns: [
      {
        name: "#",
        // width: '50px',
        cell: (row, index) => index + 1,
      },
      {
        name: "Service Name",
        cell: row => getService(row.service_id),
        // width: '200px',
        sortable: true,
      },
      {
        name: "Image",
        selector: (row) => (
          <img
            width="50px"
            height="50px"
            className="p-2"
            src={`/api/assets/gallery/${row.image}`}
          />
        ),
        // width: '200px',
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            {localStorage.getItem("deleteAuth") == 0 ?
              <Button className="_delete_btn_" onClick={() => handleDelete(row.id)}>
                <FaTrashAlt />
              </Button>
              : null}
          </>
        ),
        right: true,
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Blue Cleaning Group | Gallery</title>
      </Helmet>

      <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        {/* <ToastContainer /> */}
        <section class="wrapper">
          <div class="row">
            <div class="col-lg-12">
              <span className="p-3">Gallery</span>
              <section className="card m-3">
                <div className="addcareer p-2">
                  {localStorage.getItem("addAuth") == 0 ?
                    <Button className="Add_btn_" onClick={() => handleShow()}>
                      Add Gallery
                    </Button>
                    : null}
                </div>

                <div className="main">
                  <DataTables data={data} columns={tableData.columns} title="Gallery"  />
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="adminpass">
          <Modal.Title>Add Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Service Name</Form.Label>
              <Form.Select
                aria-label="Select Service Type"
                {...register("service", {
                  required: true,
                  onChange: (e) => setServiceId(e.target.value),
                })}
              >
                <option value="">Select Service Type</option>
                {services?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors.service && (
                <div className="errMsg">Please Select Service</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gallery Image</Form.Label>
              <Form.Control
                type="file"
                {...register("image", {
                  required: true,
                  onChange: (e) => setFile(e.target.files[0]),
                })}
              />
              {errors.image && (
                <div className="errMsg">Please Select Gallery Image</div>
              )}
              <div className="text-center p-3">
                <img
                  src={file ? URL.createObjectURL(file) : null}
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </div>
            </Form.Group>
            <div className="_add_float mt-2">
              <Button
                variant="secondary"
                onClick={handleClose}
                className="_close_btn"
              >
                Close
              </Button>
              <Button className="Add_btn_" type="submit">Update</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GalleryAdmin;
