import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

const Profile = () => {

    const [adminProfile, setAdminProfile] = useState([]);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [passShow, setPassShow] = useState(false);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [fb, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [youtube, setYoutube] = useState('');
    const [logo, setLogo] = useState('');
    const [map, setMap] = useState('');
    const [updatelogo, setUpdateLogo] = useState('');
    const [pending, setPending] = useState(true);
    const [password, setPassword] = useState('');


    const getData = () => {
        axios.get("/api/adminProfile", {
            admin_type: localStorage.getItem("adminType"),
            email: localStorage.getItem("adminEmail")

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setPending(false);
            setAdminProfile(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        adminProfile?.map((admin) => {
            setId(admin.id);
            setName(admin.name);
            setEmail(admin.email);
            setPhone(admin.phone);
            setAddress(admin.address);
            setLogo(admin.logo);
            setTwitter(admin.twitter);
            setFacebook(admin.fb);
            setLinkedIn(admin.linkedIn);
            setYoutube(admin.youtube);
            setInstagram(admin.instragram);
            setMap(admin.map);
        })

    }, [adminProfile]);



    const notify = () => toast.info("Profile Update Successfully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {

        setPending(true);
        axios.get('/api/updateProfile', {
            admin_type: localStorage.getItem("adminType"),
            id: id,
            name: name,
            email: email,
            password: password,
            phone: phone,
            address: address,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    const onUpdate = (data) => {

        axios.get('/api/updateProfile', {
            id: id,
            admin_type: localStorage.getItem("adminType"),
            name: name,
            email: email,
            password: password,
            phone: phone,
            address: address,
            logo: updatelogo,
            youtube: youtube,
            twitter: twitter,
            instragram: instagram,
            linkedIn: linkedIn,
            map: map,
            fb: fb,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }



    function getSuperAdmin(admin) {
        if (admin == 0) {
            return (
                <>


                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control type="file"
                            {...register('image', {
                                required: false,
                                onChange: (e) => setUpdateLogo(e.target.files[0]),
                            })}

                        />
                        {errors.image && <span className='errMsg'>Please select image</span>}
                    </Form.Group>
                    <div className='col-lg-6 text-center'>
                        <Form.Label>Image Preview</Form.Label>
                        <div></div>

                        <img src={updatelogo == "" ? `/api/assets/logo/${logo}` : URL.createObjectURL(updatelogo)} style={{ maxWidth: "200px", maxHeight: "100px" }} width="200px" height="100px" />
                    </div>

                    <Form.Group className="col-md-6 pb-4" >
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control type="text" placeholder='Please enter Twitter Link'
                            value={twitter}
                            {...register('Twitter', {
                                required: false,
                                onChange: (e) => setTwitter(e.target.value),
                            })}
                        />
                        {errors.Twitter && errors.Twitter.type === "required" && <span className='errMsg'>Please Enter Twitter Link</span>}
                    </Form.Group>

                    <Form.Group className="col-md-6 pb-4" >
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control type="text" placeholder='Please enter Facebook Link'
                            value={fb}
                            {...register('Facebook', {
                                required: false,
                                onChange: (e) => setFacebook(e.target.value),
                            })}
                        />
                        {errors.Facebook && errors.Facebook.type === "required" && <span className='errMsg'>Please Enter Facebook Link</span>}
                    </Form.Group>

                    <Form.Group className="col-md-6 pb-4" >
                        <Form.Label>Youtube</Form.Label>
                        <Form.Control type="text" placeholder='Please enter Youtube Link'
                            value={youtube}
                            {...register('Youtube', {
                                required: false,
                                onChange: (e) => setYoutube(e.target.value),
                            })}
                        />
                        {errors.Youtube && errors.Youtube.type === "required" && <span className='errMsg'>Please Enter Youtube Link</span>}
                    </Form.Group>

                    <Form.Group className="col-md-6 pb-4" >
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control type="text" placeholder='Please enter instagram Link'
                            value={instagram}
                            {...register('instagram', {
                                required: false,
                                onChange: (e) => setInstagram(e.target.value),
                            })}
                        />
                        {errors.instagram && errors.instagram.type === "required" && <span className='errMsg'>Please Enter instagram Link</span>}
                    </Form.Group>

                    <Form.Group className="col-md-6 pb-4" >
                        <Form.Label>Linkedin</Form.Label>
                        <Form.Control type="text" placeholder='Please enter Linkedin Link'
                            value={linkedIn}
                            {...register('Linkedin', {
                                required: false,
                                onChange: (e) => setLinkedIn(e.target.value),
                            })}
                        />
                        {errors.Linkedin && errors.Linkedin.type === "required" && <span className='errMsg'>Please Enter Linkedin Link</span>}
                    </Form.Group>


                    <Form.Group className="col-md-6 pb-4" >
                        <Form.Label>Map</Form.Label>
                        <Form.Control type="text" placeholder='Please enter Map Link'
                            value={linkedIn}
                            {...register('map', {
                                required: false,
                                onChange: (e) => setMap(e.target.value),
                            })}
                        />
                        {errors.map && errors.map.type === "required" && <span className='errMsg'>Please Enter Map Link</span>}
                    </Form.Group>

                </>
            )
        }

    }

    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | Profile</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className="p-3">Profile</span>
                            <section class="card m-3">

                                <div class="card-body">
                                    <ToastContainer />
                                    <Form className='row' autoComplete="off" onSubmit={handleSubmit(localStorage.getItem("adminType") == 0 ? onUpdate : onSubmit)}>
                                        <Form.Group className="col-md-6 pb-4" >
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder='Please enter Your name'
                                                value={name}
                                                {...register('name', {
                                                    required: false,
                                                    onChange: (e) => setName(e.target.value),
                                                })}
                                            />
                                            {errors.name && errors.name.type === "required" && <span className='errMsg'>Please Enter Your Name</span>}
                                        </Form.Group>

                                        <Form.Group className="col-md-6 pb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" value={email} autoComplete="off"
                                                {...register('email', {
                                                    required: false, maxLength: 30,
                                                    onChange: (e) => setEmail(e.target.value),
                                                })}
                                            />
                                            {errors.email && errors.email.type === "required" && <span className='errMsg'>Please Enter email Address</span>}
                                        </Form.Group>

                                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 pb-4">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Phone Number" maxLength={13}
                                                {...register('phone', {
                                                    required: false,

                                                    onChange: (e) => setPhone(e.target.value),
                                                })}
                                                value={phone}
                                            />
                                            {errors.phone && <span className='errMsg'>Please Enter Your Phone Number</span>}
                                        </Form.Group>

                                        <Form.Group className='col-md-6 pb-4'>
                                            <div style={{ position: 'relative' }}>
                                                <label className='form-label'>Change Password</label>
                                                <input type={passShow ? "text" : "password"} style={{ paddingRight: '40px' }} value={password} autoComplete="new-password" 
                                                    {...register('pass', {
                                                        required: false, maxLength: 30,
                                                        onChange: (e) => setPassword(e.target.value),
                                                    })}
                                                    className="form-control" placeholder="Enter New Password" />
                                                <Button className='eyeButton' onClick={() => setPassShow(!passShow)}>{passShow ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>
                                            </div>
                                            {errors.pass && errors.pass.type === "required" && <span className='errMsg'>Please Enter Password</span>}

                                        </Form.Group>


                                        {getSuperAdmin(localStorage.getItem("adminType"))}

                                        <Form.Group className="col-md-12 pb-4">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={address}
                                                {...register('address', {
                                                    required: false,
                                                    onChange: (e) => setAddress(e.target.value),
                                                })}
                                            />
                                            {errors.address && <span className='errMsg'>Please enter Your Address </span>}
                                        </Form.Group>

                                        <div>
                                            <Button className='Add_btn_ m-3' type='submit'>
                                                Update
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Profile;