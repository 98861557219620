import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import TextEditor from '../Editor/TextEditor';
import { Helmet } from 'react-helmet';
import DataTables from '../DataTable/DataTables';
import { FaShare, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';



const BlogAdmin = () => {


    const [blogData, setBlogData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [pending, setPending] = useState(true);


    // const actualData = blogData?.map((data) => data.content).toString();

    const notify = () => toast.info("Edit SuccessFully", { position: "top-center" });
    const deleteMsg = () =>
        toast.info("Blog Deleted Successfully", { position: "top-center" });

    const getData = () => {
        axios.get('/api/allBlog').then((response) => {
            setBlogData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    useEffect(() => {
        getData();
    }, []);

    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.get('/api/blogStatus', {
            id: id,
            status: status
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                window.location.reload(false);
            }

        }).catch((err) => {
            console.log(err);
        })
    }

    const deleteBlog = (id) => {
        axios.get('/api/deleteBlog', {
            id: id,
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 200) {
                deleteMsg();
                window.location.reload(false);
            }

        }).catch((err) => {
            console.log(err);
        })
    }


    const tableData = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },
            {
                name: "Title",
                width: '200px',
                selector: "title",
                sortable: true
            },
            {
                name: "Image",
                selector: (row) => <img style={{ width: '50px', height: '50px', padding: '10px' }} src={`assets/blog/${row.image}`} />,

                sortable: true
            },
            {
                name: "Status",
                selector: row =>

                    <select className=" col-md-4 select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
                        value={row.status}
                        id={row.id}
                        onChange={localStorage.getItem("editAuth") == 0 ? (e) => handleStatus(e) : null}
                    >
                        <option value="0">Active</option>
                        <option value="1">In-Active</option>

                    </select>
                // selector: (row) => ,


            },
            {
                name: "Action",

                cell: row =>

                    <div className='d-flex' style={{ gap: '10px' }}>

                        {localStorage.getItem("editAuth") == 0 ? <Link to={`/admin/blog/updateblog/${row.id}`}><Button className='_edit_icons'><FaEdit /></Button></Link> : null}
                        {localStorage.getItem("deleteAuth") == 0 ? <Button className='_delete_btn_' onClick={() => deleteBlog(row.id)}><FaTrashAlt /></Button> : null}
                    </div>,
                right: true

            },

        ],

    };

    // 
    // <>
    // {localStorage.getItem("editAuth") == 0 ?
    //   <Link to={`/admin/seo/updateseo/${row.id}`}>
    //     <Button className="_edit_icons">
    //       <FaEdit />
    //     </Button></Link> : "-"

    // }</>

    return (
        <>


            <Helmet>

                <title>Blue Cleaning Group | Blog</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Blog</span>
                            <section class="card m-3">
                                <div className='addcareer p-2'>
                                    {localStorage.getItem("addAuth") == 0 ?
                                        <Link to='/admin/blog/addblog'><Button className="Add_btn_">
                                            Add Blog
                                        </Button></Link> : null}
                                </div>

                                <div className="main">
                                    <DataTables data={blogData} columns={tableData.columns} title="Blogs" />
                                    {/* </DataTableExtensions> */}
                                </div>
                                {/* <div className='p-3'>
                                <TextEditor data={actualData} setUpdatedContent={setUpdatedContent} />
                            </div> */}

                                {/* <Button className='Add_btn_'>
                                    Update
                                </Button>
                                <Button className='Add_btn_'>
                                    Update
                                </Button> */}

                            </section>
                            <div>


                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default BlogAdmin
