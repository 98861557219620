import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';


const Settings = () => {

    // allSetting
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passShow, setPassShow] = useState(false);
    const [paypalClientId, setPaypalClientId] = useState('');
    const [paypalSecretKey, setPaypalSecretKey] = useState('');
    const [mapKey, setMapKey] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [globalScript, setGlobalScript] = useState('');

    const getData = () => {

        axios.get('/api/allSetting').then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        })
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        data?.map((data) => {
            setEmail(data.smtp_email);
            setPassword(data.smtp_password);
            setPaypalClientId(data.paypal_client_id);
            setPaypalSecretKey(data.paypal_secret_key);
            setMapKey(data.map_key);
            setApiKey(data.api_key);
            setGlobalScript(data.global_script);
        })

    }, [data]);

    const notify = () => toast.info("SMTP Updated Successfully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = () => {

        axios.get('/api/updateSetting', {
            smtp_email: email,
            smtp_password: password,
            paypal_client_id: paypalClientId,
            paypal_secret_key: paypalSecretKey,
            map_key: mapKey,
            api_key: apiKey,
            global_script: globalScript

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                window.location.reload(false);
               
            }

        }).catch((err) => {

            console.log(err);
        })

    }


    return (
        <>

<div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Settings</span>
                            <div className='smtp_d p-3'>
                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>

                                    <Form.Group className="d-flex pb-3" style={{ gap: '40px' }}>
                                        <Form.Label>SMTP Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={email}
                                            {...register('email', {
                                                required: false, maxLength: 30,
                                                onChange: (e) => setEmail(e.target.value),
                                            })}
                                        />
                                        {errors.email && errors.email.type === "required" && <span className='err_msg'>Please Enter email Address</span>}
                                    </Form.Group>


                                    <Form.Group>
                                        <div className="form-group d-flex pb-3" style={{ position: 'relative', gap: '40px' }}>
                                            <label className='form-label'>SMTP Password</label>
                                            <input type={passShow ? "text" : "password"} style={{ paddingRight: '40px' }} value={password}
                                                {...register('pass', {
                                                    required: false, maxLength: 30,
                                                    onChange: (e) => setPassword(e.target.value),
                                                })}
                                                className="form-control" placeholder="Enter Password" />
                                            <Button style={{ top: '8%' }} className='eyeButton' onClick={() => setPassShow(!passShow)}>{passShow ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>
                                        </div>
                                        {errors.pass && errors.pass.type === "required" && <span className='err_msg'>Please Enter Password</span>}

                                    </Form.Group>

                                    <Form.Group className="d-flex pb-3" style={{ gap: '40px' }}>
                                        <Form.Label>Paypal Client Id</Form.Label>
                                        <Form.Control type="text" placeholder="Please enter Paypal client id" value={paypalClientId}
                                            {...register('clientId', {
                                                required: false, maxLength: 30,
                                                onChange: (e) => setPaypalClientId(e.target.value),
                                            })}
                                        />
                                        {errors.email && errors.email.type === "required" && <span className='err_msg'>Please Enter Paypal Client id</span>}
                                    </Form.Group>


                                    <Form.Group className="d-flex pb-3" style={{ gap: '40px' }}>
                                        <Form.Label>Paypal Secret Key</Form.Label>
                                        <Form.Control type="text" placeholder="Please enter Paypal client id" value={paypalSecretKey}
                                            {...register('secretKey', {
                                                required: false, maxLength: 30,
                                                onChange: (e) => setPaypalSecretKey(e.target.value),
                                            })}
                                        />
                                        {errors.secretKey && errors.secretKey.type === "required" && <span className='err_msg'>Please Enter Paypal Secret Key</span>}
                                    </Form.Group>
                                    <Form.Group className="d-flex pb-3" style={{ gap: '40px' }}>
                                        <Form.Label>Map Key</Form.Label>
                                        <Form.Control type="text" placeholder="Please enter Map Key" value={mapKey}
                                            {...register('mapKey', {
                                                required: false, maxLength: 30,
                                                onChange: (e) => setMapKey(e.target.value),
                                            })}
                                        />
                                        {errors.mapKey && errors.mapKey.type === "required" && <span className='err_msg'>Please Enter map Key</span>}
                                    </Form.Group>
                                    <Form.Group className="d-flex pb-3" style={{ gap: '40px' }}>
                                        <Form.Label>Api Key</Form.Label>
                                        <Form.Control type="text" placeholder="Please enter Api Key" value={apiKey}
                                            {...register('apiKey', {
                                                required: false, maxLength: 30,
                                                onChange: (e) => setApiKey(e.target.value),
                                            })}
                                        />
                                        {errors.apiKey && errors.apiKey.type === "required" && <span className='err_msg'>Please Enter Api Key</span>}
                                    </Form.Group>

                                    <Form.Group className="d-flex pb-3" style={{ gap: '40px' }}>
                                        <Form.Label>Global Script</Form.Label>
                                        <Form.Control type="text" placeholder="Please enter Global Script" value={globalScript}
                                            {...register('globalScript', {
                                                required: false, maxLength: 30,
                                                onChange: (e) => setGlobalScript(e.target.value),
                                            })}
                                        />
                                        {errors.globalScript && errors.globalScript.type === "required" && <span className='err_msg'>Please Enter Global Script</span>}
                                    </Form.Group>


                                    <div>
                                        <Button disabled={pending ? true : false} className='Add_btn_ m-3' type='submit'>
                                            Update
                                        </Button>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>
                </section>
            </section>


        </>
    )
}

export default Settings