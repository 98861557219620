import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import OurProcess from '../common/OurProcess';
import WhyBlueCleaning from '../common/WhyBlueCleaning';
import commaImg from '../assets/Home Page Assets/Component-min.png';
import ClientReview from '../common/ClientReview';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Figure from 'react-bootstrap/Figure';
import { call } from '../Header/Header';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import bannerHome3 from '../assets/Home Page Assets/centerImg.jpg';
import { HiArrowSmRight } from 'react-icons/hi';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import Skeleton from '@mui/material/Skeleton';
import { fixedContainer, slideIn, textVariants } from '../common/motion/motion';

const Home = () => {


  const [banner, setBanner] = useState([]);
  const [services, setServices] = useState([]);
  const [recentWork, setRecentWork] = useState([]);
  const [blog, setBlog] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [subServiceId, setSubServiceId] = useState('');
  const [clients, setClients] = useState([]);
  const [myspinner, setMyspinner] = useState(false);
  const [seoContent, setSeoContent] = useState([]);
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');


  const bannerImg = banner?.map((banner) => banner.image)
  const bannerContent = banner?.map((banner) => banner.content);

  const navigate = useNavigate();


  const ServiceErr = () => toast.error("Please Select Service", { position: "top-center", });
  const SubServiceErr = () => toast.error("Please Select sub-service", { position: "top-center", });

  const getAllData = () => {

    axios.get('/api/getSeo/1').then((response) => {
      setSeoContent(response.data.data);
      console.log(response.data.data);
    }).catch((err) => {
      console.log(err);
    })

    axios.get('/api/getBanner/1'
    ).then((response) => {
      setBanner(response.data.data);
      console.log(response.data.data)
    }).catch((err) => {
      console.log(err);
    })


    axios.get('/api/getAllClient'
    ).then((response) => {
      setClients(response.data.data);
      console.log(response.data.data)

    }).catch((err) => {
      console.log(err);
    })

    axios.get('/api/getBlog'
    ).then((response) => {
      setBlog(response.data.data);
      console.log(response.data.data)

    }).catch((err) => {
      console.log(err);
    })

    axios.get('/api/getServiceHeader').then((response) => {
      // setSubmenus(response.data.data);
      setServices(response.data.data);

    }).catch((err) => {
      console.log(err);
    })

    axios.get('/api/getAllRecentwork'
    ).then((response) => {
      setRecentWork(response.data.data);
      // console.log(response.data.data);
      if (response.status === 200) {
        setMyspinner(false);
      }
    }).catch((err) => {
      console.log(err);
    })

  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllData();
    setMyspinner(true);
    Aos.init({
      duration: 1000,
    })
  }, []);



  const handleServices = (e) => {
    let id = e.target.value;
    setServiceId(id);
    if (id != "") {
      setMyspinner(true);
      axios.get(`/api/getSubServiceById/${id}`
      ).then((response) => {
        setMyspinner(false);
        setSubservices(response.data.data);
      });
    } else {
      setSubservices([]);
    }
  }

  const validate = () => {
    if (serviceId == "") {
      ServiceErr();
    } else if (subServiceId == "") {
      SubServiceErr();
    } else {

      navigate(`services/${serviceId}/${subServiceId}`);

    }
  }


  const vidRef = useRef();

  useEffect(() => { vidRef.current.play(); }, []);

  const handleSEO = () => {
    seoContent?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [seoContent]);



  return (
    <>

      <Helmet>
        <link rel="canonical" href={canonical} />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDes} />
        <meta name="keywords" content={seoKeyword} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="hweindia" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDes} />
        <meta name="robots" content={robot} />
        <script>{seoScript}</script>

      </Helmet>

      <div className={myspinner ? 'spinner_d' : 'd-none'}>
        <Spinner animation="border" className='mySpinner' />
      </div>

      <section class="content-s"

      >
        <div class="banner-d">
          {myspinner ?
            <>

              <Skeleton
                variant="rectangular"
                width="33%"
                height="100vh"
                animation="wave"

              />
              <Skeleton
                variant="rectangular"
                width="33%"
                height="100vh"
                animation="wave"

              />
              <Skeleton
                variant="rectangular"
                width="33%"
                height="100vh"
                animation="wave"
              />

            </>
            :
            services?.map((service) => {
              return (
                <>
                  {/* <div className='banner-img item item--medium' style={{ backgroundImage: `url(/api/assets/service/${service.image})`, position: 'absolute' }}></div> */}
                  <Link to={`/services/${service.id}/${service.name.replace(/\s+/g, '-')}`} class="banner-img item item--medium" 
                  style={{ backgroundImage: `url(/api/assets/service/${service.image})`  }}
                  >
                    <motion.div class="content-d"
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: false, amount: 0.1 }}
                    >
                      <motion.h1
                        variants={textVariants('up', 0.1)}
                      >{service.name}</motion.h1>
                      <Button className=' mt-3 m-auto'>Book Now <HiArrowSmRight style={{ fontSize: '24px' }} /></Button>
                    </motion.div>
                  </Link>
                </>
              )
            })
          }

        </div>
      </section>


      <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }} >

        <ToastContainer />

        <WhyBlueCleaning />

        <section className='our_clients-s' style={{ position: 'relative' }}>
          <video src="https://bcg.giwdevelopment.com/assets/video/clients1.mp4" autoPlay={true} preload="auto" ref={vidRef} autoBuffer autoLoop loop muted id="video_">

          </video>

          <div className='our_clients banner' style={{ minHeight: '500px' }} >
            <center className="mb-4">
              <motion.h5
                variants={textVariants('up', 0.1)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                style={{ justifyContent: 'center' }}>Our Clients</motion.h5>
              <motion.h3
                variants={textVariants('up', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                className='title' style={{ position: 'relative', zIndex: '2' }}>Our Happy Clients</motion.h3>
            </center>
            <div className='container-fluid'>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={true}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="clientsSwiper"

                breakpoints={{
                  320: {

                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },

                  640: {

                    slidesPerView: 1,
                  },

                  768: {

                    slidesPerView: 2,
                  },
                  992: {

                    slidesPerView: 3,
                  },

                }}
              >
                {clients?.map((client, index) => {
                  return (
                    <SwiperSlide key={client.id} className="client_s">
                      <motion.div
                        variants={textVariants('left', index / 10)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        className='client_div'>
                        <img className='comma' src={commaImg} />
                        <div className='clients_review'>
                          <img style={{ width: '66px', height: '66px' }} src={`/api/assets/happyclient/${client.image}`} />
                          <div>
                            <p>{client.message}</p>
                          </div>
                        </div>
                        <div className='client_info'>
                          <div>
                            <span>
                              {client.name}
                            </span>
                            <div className='star-d'>
                              <ClientReview star={client.stars} />
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </SwiperSlide>
                  )

                })}
              </Swiper>
            </div>
          </div>
        </section>


        <section className="our_work-s">
          <div className='our_work'>
            <center className="mb-4">
              <motion.h5
                variants={textVariants('up', 0.1)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                style={{ justifyContent: 'center' }}>Our Work</motion.h5>
              <motion.h3
                variants={textVariants('up', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                className='title'>Recent Projects</motion.h3>
            </center>
            <div className='container-fluid'>
              <div class="gallery">
                {recentWork?.map((work, index) => {
                  return (
                    <motion.div class="gallery-item"
                      variants={textVariants('left', index / 10)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                    >
                      <img class="gallery-image" src={`/api/assets/recentwork/${work.after_image}`} alt="work" />
                    </motion.div>
                  )
                }
                )}
              </div>
            </div>
          </div>
        </section>

        <section className='container company-s'>
          <div className='container-fluid our-company'>

            <div className='d-flex m-auto' >
              <div className='pb-4' data-aos="fade-up" data-aos-once="true" >
                <motion.h3
                  variants={textVariants('up', 0.1)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  className='title'>Professional Cleaning Service By Blue Cleaning </motion.h3>
                <motion.p
                  variants={textVariants('up', 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  className='description'>Blue Cleaning Group Pty Ltd is one of the most reputable cleaning providers
                  in Canberra and surrounds. At Blue Cleaning, we firmly believe in the values
                  of relationships and these is reflected on our high repeat contracts and our
                  employees retention rate.<br></br>
                  Our Organization is committed to professional and attention to detail.
                </motion.p>
              </div>

            </div>
            <div class="blog_button">
              <Link to='/contactus' style={{ textDecoration: 'none' }} >
                <motion.Button
                  variants={textVariants('up', 0.3)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  className='appoinment-btn'

                >

                  Get A Free Quote</motion.Button>
              </Link>
            </div>
          </div>
        </section>

        <section className='container book_now-s'>
          <div style={{ position: 'relative' }} className="container-fluid">
            <motion.div
              variants={textVariants('up', 0.1)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.1 }}
              className='center_i' style={{ backgroundColor: "#DBA11C" }}>
              <motion.img
                variants={textVariants('up', 0.3)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.1 }}
                src={bannerHome3} className="w-100" />
            </motion.div>
            <div class="form-d">
              <motion.div
                variants={textVariants('up', 0.1)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.1 }}
                className='call_d' style={{ backgroundColor: "#146FB7" }}>
                {/* <section> */}
                <div className='bookUsNow text-center' >
                  <motion.h2
                    variants={textVariants('up', 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                  >Book Us Now! For Professional Cleaning Services </motion.h2>
                  <div className='btn_div'>

                    <a href={`tel:${call}`}>
                      <motion.span
                        variants={textVariants('up', 0.3)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                      ><Button

                        className='call-btn'>

                          CALL US NOW</Button>
                      </motion.span></a>

                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <OurProcess />
      </div >
    </>
  )
}

export default Home