import React, { useState, useEffect } from 'react';
import TextEditor from '../Editor/TextEditor';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddSubService = () => {

    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [pending, setPending] = useState(true);
    const [robot, setRobot] = useState('');
    const [services, setServices] = useState([]);
    const [serviceId, setServiceId] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [bannerText, setBannerText] = useState('');
    const [icon, setIcon] = useState('');
    const [subServiceType, setSubServiceType] = useState('');
    const [extra, setExtra] = useState('0');
    const [initialPrice, setInitialPrice] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [minHour, setMinHour] = useState('');
    const [minHourPrice, setMinHourPrice] = useState('');
    const [additionalPrice, setAdditionalPrice] = useState('');
    const [advanceType, setAdvanceType] = useState('1');
    const [advanceAmount, setAdvanceAmount] = useState('');
    const [regularCleaning, setRegularCleaning] = useState('2');
    const [propertyType, setPropertyType] = useState('2');
    const [gst, setGst] = useState('');
    const [canonical, setCanonical] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoFollow, setSeoFollow] = useState('');
    const [content, setContent] = useState('');
    const [updateContentTitle, setUpdateContentTitle] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');



    // formData manage using single state object
    const [formData, setFormData] = useState({
        service_id_one: '1',
        type_one: '',
        extras_one: '0',
        initial_price_one: '',
        min_price_one: '',
        min_hour_one: '',
        min_hour_price_one: '',
        adtnl_hour_price_one: '',
        regular_service_one: '',
        prop_typeask_one: '',
        content_one: '',
        image_one: '',
        gst_one: '',

        service_id_two: '2',
        type_two: '',
        extras_two: '0',
        initial_price_two: '',
        min_price_two: '',
        min_hour_two: '',
        min_hour_price_two: '',
        adtnl_hour_price_two: '',
        regular_service_two: '',
        prop_typeask_two: '',
        content_two: '',
        image_two: '',
        gst_two: '',

        service_id_three: '3',
        type_three: '',
        extras_three: '0',
        initial_price_three: '',
        min_price_three: '',
        min_hour_three: '',
        min_hour_price_three: '',
        adtnl_hour_price_three: '',
        regular_service_three: '',
        prop_typeask_three: '',
        content_three: '',
        image_three: '',
        gst_three: '',

        name: '',
        banner_text: '',
        banner_image: '',
        canonical: '',
        seo_title: '',
        seo_description: '',
        seo_keywords: '',
        seo_follow: '',
        seo_script: '',
    });


    function handleInputChange(formField, formValue) {
        setFormData(prevFormData => ({
            ...prevFormData,
            [formField]: formValue
        }));
    }


    const handleEditorChange = (contentField, content) => {
        setFormData((prevFormData) => ({ ...prevFormData, [contentField]: content }));
    };

    // const handleType = (contentField, content) => {
    //     setFormData((prevFormData) => ({ ...prevFormData, [contentField]: content }));
    // };


    const serviceData = () => {
        axios.get('/api/getServiceHeader').then((response) => {
            setServices(response.data.data);
            setPending(false);
        }).catch((err) => {
            console.log(err);
            setPending(false);
        });
    }

    useEffect(() => {
        serviceData();
    }, []);

    const navigate = useNavigate();


    const notify = () => toast.info("Sub Service Added SuccessFully", { position: "top-center" });

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        setPending(true)
        axios.get('/api/addSubService', formData
            // {
            //     service_id: serviceId,
            //     type: subServiceType,
            //     name: name,
            //     banner_text: bannerText,
            //     content: updatedContent,
            //     extras: extra,
            //     // advance: advanceType,
            //     // advance_amount: advanceType == 2 ? advanceAmount : null,
            //     seo_title: seoTitle,
            //     seo_description: seoDescription,
            //     seo_keywords: seoKeyword,
            //     seo_follow: seoFollow,
            //     seo_script: seoScript,
            //     canonical: canonical,
            //     regular_service: regularCleaning,
            //     image: image,
            //     icon: icon,
            //     banner_image: bannerImage,
            //     initial_price: initialPrice,
            //     min_price: minPrice,
            //     min_hour: minHour,
            //     min_hour_price: minHourPrice,
            //     adtnl_hour_price: additionalPrice,
            //     prop_typeask: propertyType,
            //     gst: gst

            // }
            , {
                method: 'POST',
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }

        ).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false)
                navigate('/admin/subservice')

            }
        })

    }

    useEffect(() => {
        reset({
            initial: "",
            minPrice: "",
            minHour: "",
            minHourPrice: "",
            adtnlPrice: "",
        })
    }, [formData.type_one]);



    function getServiceData(type, type_) {
        if (type_ == 'type_one') {
            switch (type) {
                case '0':
                    return (
                        <>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Initial Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.initial_price_one || ''}
                                    {...register('initial_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('initial_price_one', e.target.value),
                                    })}
                                />
                                {errors.initial_price_one && <span className='err_msg'>Please enter Initial Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_one || ''}
                                    {...register('min_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_one', e.target.value),
                                    })}
                                />
                                {errors.min_price_one && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>
                        </>
                    )
                case '1':
                    return (
                        <>

                        </>
                    )
                case '2':
                    return (
                        <>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_one || ''}
                                    {...register('min_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_one', e.target.value),
                                    })}
                                />
                                {errors.min_price_one && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_one || ''}
                                    {...register('min_hour_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_one', e.target.value),
                                    })}

                                />
                                {errors.min_hour_one && <span className='err_msg'>Please enter minimum Hour </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_price_one}
                                    {...register('min_hour_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_price_one', e.target.value),
                                    })}
                                />
                                {errors.min_hour_price_one && <span className='err_msg'>Please enter minimum Hour Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Additional Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.adtnl_hour_price_one}
                                    {...register('adtnl_hour_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('adtnl_hour_price_one', e.target.value),
                                    })}
                                />
                                {errors.adtnl_hour_price_one && <span className='err_msg'>Please enter Additional Hour Price </span>}
                            </Form.Group>
                        </>
                    )
                case '3':
                    return (
                        <>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Initial Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.initial_price_one || ''}
                                    {...register('initial_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('initial_price_one', e.target.value),
                                    })}
                                />
                                {errors.initial_price_one && <span className='err_msg'>Please enter Initial Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_one || ''}
                                    {...register('min_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_one', e.target.value),
                                    })}
                                />
                                {errors.min_price_one && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>

                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_one || ''}
                                    {...register('min_hour_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_one', e.target.value),
                                    })}

                                />
                                {errors.min_hour_one && <span className='err_msg'>Please enter minimum Hour </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_price_one}
                                    {...register('min_hour_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_price_one', e.target.value),
                                    })}
                                />
                                {errors.min_hour_price_one && <span className='err_msg'>Please enter minimum Hour Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Additional Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.adtnl_hour_price_one}
                                    {...register('adtnl_hour_price_one', {
                                        required: true,
                                        onChange: (e) => handleInputChange('adtnl_hour_price_one', e.target.value),
                                    })}
                                />
                                {errors.adtnl_hour_price_one && <span className='err_msg'>Please enter Additional Hour Price </span>}
                            </Form.Group>
                        </>
                    )
            }
        } else if (type_ == 'type_two') {
            switch (type) {
                case '0':
                    return (
                        <>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Initial Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.initial_price_two || ''}
                                    {...register('initial_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('initial_price_two', e.target.value),
                                    })}
                                />
                                {errors.initial_price_two && <span className='err_msg'>Please enter Initial Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_two || ''}
                                    {...register('min_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_two', e.target.value),
                                    })}
                                />
                                {errors.min_price_two && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>
                        </>
                    )
                case '1':
                    return (
                        <>

                        </>
                    )
                case '2':
                    return (
                        <>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_two || ''}
                                    {...register('min_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_two', e.target.value),
                                    })}
                                />
                                {errors.min_price_two && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_two || ''}
                                    {...register('min_hour_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_two', e.target.value),
                                    })}

                                />
                                {errors.min_hour_two && <span className='err_msg'>Please enter minimum Hour </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_price_two}
                                    {...register('min_hour_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_price_two', e.target.value),
                                    })}
                                />
                                {errors.min_hour_price_two && <span className='err_msg'>Please enter minimum Hour Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Additional Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.adtnl_hour_price_two}
                                    {...register('adtnl_hour_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('adtnl_hour_price_two', e.target.value),
                                    })}
                                />
                                {errors.adtnl_hour_price_two && <span className='err_msg'>Please enter Additional Hour Price </span>}
                            </Form.Group>
                        </>
                    )
                case '3':
                    return (
                        <>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Initial Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.initial_price_two || ''}
                                    {...register('initial_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('initial_price_two', e.target.value),
                                    })}
                                />
                                {errors.initial_price_two && <span className='err_msg'>Please enter Initial Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_two || ''}
                                    {...register('min_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_two', e.target.value),
                                    })}
                                />
                                {errors.min_price_two && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>

                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_two || ''}
                                    {...register('min_hour_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_two', e.target.value),
                                    })}

                                />
                                {errors.min_hour_two && <span className='err_msg'>Please enter minimum Hour </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_price_two}
                                    {...register('min_hour_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_price_two', e.target.value),
                                    })}
                                />
                                {errors.min_hour_price_two && <span className='err_msg'>Please enter minimum Hour Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Additional Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.adtnl_hour_price_two}
                                    {...register('adtnl_hour_price_two', {
                                        required: true,
                                        onChange: (e) => handleInputChange('adtnl_hour_price_two', e.target.value),
                                    })}
                                />
                                {errors.adtnl_hour_price_two && <span className='err_msg'>Please enter Additional Hour Price </span>}
                            </Form.Group>
                        </>
                    )
            }
        } else if (type_ == 'type_three') {
            switch (type) {
                case '0':
                    return (
                        <>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Initial Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.initial_price_three || ''}
                                    {...register('initial_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('initial_price_three', e.target.value),
                                    })}
                                />
                                {errors.initial_price_three && <span className='err_msg'>Please enter Initial Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_three || ''}
                                    {...register('min_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_three', e.target.value),
                                    })}
                                />
                                {errors.min_price_three && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>
                        </>
                    )
                case '1':
                    return (
                        <>

                        </>
                    )
                case '2':
                    return (
                        <>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_three || ''}
                                    {...register('min_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_three', e.target.value),
                                    })}
                                />
                                {errors.min_price_three && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_three || ''}
                                    {...register('min_hour_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_three', e.target.value),
                                    })}

                                />
                                {errors.min_hour_three && <span className='err_msg'>Please enter minimum Hour </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_price_three}
                                    {...register('min_hour_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_price_three', e.target.value),
                                    })}
                                />
                                {errors.min_hour_price_three && <span className='err_msg'>Please enter minimum Hour Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Additional Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.adtnl_hour_price_three}
                                    {...register('adtnl_hour_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('adtnl_hour_price_three', e.target.value),
                                    })}
                                />
                                {errors.adtnl_hour_price_three && <span className='err_msg'>Please enter Additional Hour Price </span>}
                            </Form.Group>
                        </>
                    )
                case '3':
                    return (
                        <>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Initial Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.initial_price_three || ''}
                                    {...register('initial_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('initial_price_three', e.target.value),
                                    })}
                                />
                                {errors.initial_price_three && <span className='err_msg'>Please enter Initial Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label>Min Order Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_price_three || ''}
                                    {...register('min_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_price_three', e.target.value),
                                    })}
                                />
                                {errors.min_price_three && <span className='err_msg'>Please enter Minimum Price </span>}
                            </Form.Group>

                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_three || ''}
                                    {...register('min_hour_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_three', e.target.value),
                                    })}

                                />
                                {errors.min_hour_three && <span className='err_msg'>Please enter minimum Hour </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-2 pb-5">
                                <Form.Label> Min Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.min_hour_price_three}
                                    {...register('min_hour_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('min_hour_price_three', e.target.value),
                                    })}
                                />
                                {errors.min_hour_price_three && <span className='err_msg'>Please enter minimum Hour Price </span>}
                            </Form.Group>
                            <Form.Group className=" col-md-3 pb-5">
                                <Form.Label>Additional Hour Price</Form.Label>
                                <Form.Control type="number"
                                    value={formData.adtnl_hour_price_three}
                                    {...register('adtnl_hour_price_three', {
                                        required: true,
                                        onChange: (e) => handleInputChange('adtnl_hour_price_three', e.target.value),
                                    })}
                                />
                                {errors.adtnl_hour_price_three && <span className='err_msg'>Please enter Additional Hour Price </span>}
                            </Form.Group>
                        </>
                    )
            }
        }
    }

    return (
        <>
            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Add Sub Service</span>
                            <div className='m-3'>
                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>Sub Service Name</Form.Label>
                                        <Form.Control type="text" placeholder='Please enter service name'
                                            value={formData.name || ''}
                                            {...register('name', {
                                                required: true,
                                                // onChange: (e) => setName(e.target.value),
                                                onChange: (e) => handleInputChange('name', e.target.value)
                                            })}
                                        />
                                        {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Name</span>}
                                    </Form.Group>

                                    <hr className='py-5'></hr>

                                    <div className='col-md-7'>
                                        <Form.Group className=" col-md-12 pb-5">
                                            <Form.Label>Service Type</Form.Label>
                                            <Form.Control type="text" placeholder='Please enter service Type'
                                                value={"Construction & Industrial Cleaning"} disabled
                                                {...register('service_id_one', {
                                                    required: false,
                                                    // onChange: (e) => setName(e.target.value),
                                                    // onChange: (e) => handleInputChange('name', e.target.value)
                                                })}
                                            />
                                            {errors.service_id_one && errors.service_id_one.type === "required" && <span className='err_msg'>Please Select Service</span>}
                                            {/* <Form.Select value={formData.service_id_one || ''}
                                                {...register("service_id_one", {
                                                    onChange: (e) => handleInputChange('service_id_one', e.target.value),
                                                    required: true,
                                                })}
                                            >
                                                <option value="">Select Service</option>
                                                {services?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {errors.service_id_one && <span className='err_msg'>Please Select Service</span>} */}
                                        </Form.Group>
                                    </div>

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Sub Service Type</Form.Label>
                                        <Form.Select value={formData.type_one || ''}

                                            {...register('type_one', {
                                                required: true,
                                                onChange: (e) => handleInputChange('type_one', e.target.value),
                                            })}

                                        // onChange={(e) => setSubServiceType(e.target.value)}
                                        >
                                            <option value="">Select</option>
                                            <option value="0">Fixed</option>
                                            <option value="1">Enquiry</option>
                                            <option value="2">Hourly</option>
                                            <option value="3">Fixed/Hourly</option>


                                        </Form.Select>
                                        {errors.type_one && <span className='err_msg'>Please Select Service Type</span>}
                                    </Form.Group>
                                    <Form.Group className=" col-md-2 pb-5">
                                        <Form.Label>Extras</Form.Label>
                                        <Form.Select value={formData.extras_one}
                                            {...register('extras_one', {
                                                required: false,
                                                onChange: (e) => handleInputChange('extras_one', e.target.value),
                                            })}
                                        >

                                            <option value="0">No</option>
                                            <option value="1">Yes</option>


                                        </Form.Select>
                                        {errors.extras_one && <span className='err_msg'>Please select extra</span>}
                                    </Form.Group>


                                    {getServiceData(formData.type_one, 'type_one')}

                                    {/* <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Advance Payment</Form.Label>
                                        <Form.Select value={advanceType}
                                            {...register('advanceType', {
                                                required: false,
                                                onChange: (e) => setAdvanceType(e.target.value),
                                            })}
                                        >
                                            
                                            <option value="1">No</option>
                                            <option value="2">Yes</option>

                                        </Form.Select>
                                        {errors.advanceType && <span className='err_msg'>Please select Advance Payment</span>}
                                    </Form.Group> */}
                                    {/* {advanceType == 2 ?
                                        <Form.Group className=" col-md-3 pb-5">
                                            <Form.Label>Advance Amount</Form.Label>
                                            <Form.Control type="number"
                                                value={advanceAmount}
                                                {...register('advanceAmount', {
                                                    required: true,
                                                    onChange: (e) => setAdvanceAmount(e.target.value),
                                                })}
                                            />
                                            {errors.advanceAmount && <span className='err_msg'>Please enter Advance Amount </span>}
                                        </Form.Group>
                                        : null} */}

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Regular Cleaning</Form.Label>
                                        <Form.Select value={formData.regular_service_one || ''}
                                            {...register('regular_service_one', {
                                                required: true,
                                                onChange: (e) => handleInputChange('regular_service_one', e.target.value),
                                            })}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.regular_service_one && <span className='err_msg'>Please select Regular Cleaning</span>}
                                    </Form.Group>

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Property Type</Form.Label>
                                        <Form.Select value={formData.prop_typeask_one || ''}
                                            {...register('prop_typeask_one', {
                                                required: true,
                                                onChange: (e) => handleInputChange('prop_typeask_one', e.target.value),
                                            })}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.prop_typeask_one && <span className='err_msg'>Please select Property Type</span>}
                                    </Form.Group>

                                    <Form.Group className="col-md-3 pb-5" >
                                        <Form.Label>GST(%)</Form.Label>
                                        <Form.Control type="number"
                                            value={formData.gst_one || ''}

                                            {...register('gst_one', {
                                                required: false,
                                                onChange: (e) => handleInputChange('gst_one', e.target.value),
                                            })}

                                        />
                                        {errors.gst_one && <span className='err_msg'>Please enter GST(%)</span>}
                                    </Form.Group>


                                    <div className='col-lg-12 pb-5'>
                                        <Form.Label>Sub Service Content</Form.Label>
                                        <TextEditor data={formData.content_one || ''} setUpdatedContent={(newContent) => handleEditorChange('content_one', newContent)} />
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Service Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('image_one', {
                                                required: true,
                                                onChange: (e) => handleInputChange('image_one', e.target.files[0]),
                                            })}

                                        />
                                        {errors.image_one && <span className='err_msg'>Please select image</span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            <img src={formData.image_one ? URL.createObjectURL(formData.image_one) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                        </div>
                                    </div>

                                    {/* <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Icon</Form.Label>
                                        <Form.Control type="file"
                                            {...register('icon', {
                                                required: true,
                                                onChange: (e) => setIcon(e.target.files[0]),
                                            })}

                                        />
                                        {errors.icon && <span className='err_msg'>Please select icon </span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            <img className='bg-dark' src={icon ? URL.createObjectURL(icon) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                        </div>
                                    </div> */}

                                    <hr className='py-5'></hr>

                                    <div className='col-md-7'>
                                        <Form.Group className=" col-md-12 pb-5">
                                            <Form.Label>Service Type</Form.Label>
                                            <Form.Control type="text" placeholder='Please enter service Type'
                                                value={"Residential Cleaning"} disabled
                                                {...register('service_id_two', {
                                                    required: false,
                                                })}
                                            />
                                            {errors.service_id_two && errors.service_id_two.type === "required" && <span className='err_msg'>Please Select Service</span>}
                                        </Form.Group>
                                    </div>

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Sub Service Type</Form.Label>
                                        <Form.Select value={formData.type_two || ''}

                                            {...register('type_two', {
                                                required: true,
                                                onChange: (e) => handleInputChange('type_two', e.target.value),
                                            })}
                                        >
                                            <option value="">Select</option>
                                            <option value="0">Fixed</option>
                                            <option value="1">Enquiry</option>
                                            <option value="2">Hourly</option>
                                            <option value="3">Fixed/Hourly</option>


                                        </Form.Select>
                                        {errors.type_two && <span className='err_msg'>Please Select Service Type</span>}
                                    </Form.Group>
                                    <Form.Group className=" col-md-2 pb-5">
                                        <Form.Label>Extras</Form.Label>
                                        <Form.Select value={formData.extras_two}
                                            {...register('extras_two', {
                                                required: false,
                                                onChange: (e) => handleInputChange('extras_two', e.target.value),
                                            })}
                                        >

                                            <option value="0">No</option>
                                            <option value="1">Yes</option>


                                        </Form.Select>
                                        {errors.extras_two && <span className='err_msg'>Please select extra</span>}
                                    </Form.Group>

                                    {getServiceData(formData.type_two, 'type_two')}

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Regular Cleaning</Form.Label>
                                        <Form.Select value={formData.regular_service_two || ''}
                                            {...register('regular_service_two', {
                                                required: true,
                                                onChange: (e) => handleInputChange('regular_service_two', e.target.value),
                                            })}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.regular_service_two && <span className='err_msg'>Please select Regular Cleaning</span>}
                                    </Form.Group>

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Property Type</Form.Label>
                                        <Form.Select value={formData.prop_typeask_two || ''}
                                            {...register('prop_typeask_two', {
                                                required: true,
                                                onChange: (e) => handleInputChange('prop_typeask_two', e.target.value),
                                            })}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.prop_typeask_two && <span className='err_msg'>Please select Property Type</span>}
                                    </Form.Group>

                                    <Form.Group className="col-md-3 pb-5" >
                                        <Form.Label>GST(%)</Form.Label>
                                        <Form.Control type="number"
                                            value={formData.gst_two || ''}

                                            {...register('gst_two', {
                                                required: false,
                                                onChange: (e) => handleInputChange('gst_two', e.target.value),
                                            })}

                                        />
                                        {errors.gst_two && <span className='err_msg'>Please enter GST(%)</span>}
                                    </Form.Group>


                                    <div className='col-lg-12 pb-5'>
                                        <Form.Label>Sub Service Content</Form.Label>
                                        <TextEditor data={formData.content_two} setUpdatedContent={(newContent) => handleEditorChange('content_two', newContent)} />
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Service Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('image_two', {
                                                required: true,
                                                onChange: (e) => handleInputChange('image_two', e.target.files[0]),
                                            })}

                                        />
                                        {errors.image_two && <span className='err_msg'>Please select image</span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            <img src={formData.image_two ? URL.createObjectURL(formData.image_two) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                        </div>
                                    </div>

                                    <hr className='py-5'></hr>

                                    <div className='col-md-7'>
                                        <Form.Group className=" col-md-12 pb-5">
                                            <Form.Label>Service Type</Form.Label>
                                            <Form.Control type="text" placeholder='Please enter service Type'
                                                value={"Commercial Cleaning"} disabled
                                                {...register('service_id_three', {
                                                    required: false,
                                                })}
                                            />
                                            {errors.service_id_three && errors.service_id_three.type === "required" && <span className='err_msg'>Please Select Service</span>}
                                        </Form.Group>
                                    </div>

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Sub Service Type</Form.Label>
                                        <Form.Select value={formData.type_three || ''}

                                            {...register('type_three', {
                                                required: true,
                                                onChange: (e) => handleInputChange('type_three', e.target.value),
                                            })}
                                        >
                                            <option value="">Select</option>
                                            <option value="0">Fixed</option>
                                            <option value="1">Enquiry</option>
                                            <option value="2">Hourly</option>
                                            <option value="3">Fixed/Hourly</option>


                                        </Form.Select>
                                        {errors.type_three && <span className='err_msg'>Please Select Service Type</span>}
                                    </Form.Group>
                                    <Form.Group className=" col-md-2 pb-5">
                                        <Form.Label>Extras</Form.Label>
                                        <Form.Select value={formData.extras_three}
                                            {...register('extras_three', {
                                                required: false,
                                                onChange: (e) => handleInputChange('extras_three', e.target.value),
                                            })}
                                        >

                                            <option value="0">No</option>
                                            <option value="1">Yes</option>


                                        </Form.Select>
                                        {errors.extras_three && <span className='err_msg'>Please select extra</span>}
                                    </Form.Group>

                                    {getServiceData(formData.type_three, 'type_three')}

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Regular Cleaning</Form.Label>
                                        <Form.Select value={formData.regular_service_three || ''}
                                            {...register('regular_service_three', {
                                                required: true,
                                                onChange: (e) => handleInputChange('regular_service_three', e.target.value),
                                            })}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.regular_service_three && <span className='err_msg'>Please select Regular Cleaning</span>}
                                    </Form.Group>

                                    <Form.Group className=" col-md-3 pb-5">
                                        <Form.Label>Property Type</Form.Label>
                                        <Form.Select value={formData.prop_typeask_three || ''}
                                            {...register('prop_typeask_three', {
                                                required: true,
                                                onChange: (e) => handleInputChange('prop_typeask_three', e.target.value),
                                            })}
                                        >
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.prop_typeask_three && <span className='err_msg'>Please select Property Type</span>}
                                    </Form.Group>

                                    <Form.Group className="col-md-3 pb-5" >
                                        <Form.Label>GST(%)</Form.Label>
                                        <Form.Control type="number"
                                            value={formData.gst_three || ''}

                                            {...register('gst_three', {
                                                required: false,
                                                onChange: (e) => handleInputChange('gst_three', e.target.value),
                                            })}

                                        />
                                        {errors.gst_three && <span className='err_msg'>Please enter GST(%)</span>}
                                    </Form.Group>


                                    <div className='col-lg-12 pb-5'>
                                        <Form.Label>Sub Service Content</Form.Label>
                                        <TextEditor data={formData.content_three} setUpdatedContent={(newContent) => handleEditorChange('content_three', newContent)} />
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Service Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('image_three', {
                                                required: true,
                                                onChange: (e) => handleInputChange('image_three', e.target.files[0]),
                                            })}

                                        />
                                        {errors.image_three && <span className='err_msg'>Please select image</span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            <img src={formData.image_three ? URL.createObjectURL(formData.image_three) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                        </div>
                                    </div>

                                    <hr className='py-4'></hr>

                                    <Form.Group className="col-md-7 pb-5" >
                                        <Form.Label>Banner Text</Form.Label>
                                        <Form.Control type="text"
                                            value={formData.banner_text || ''}

                                            {...register('banner_text', {
                                                required: true,
                                                onChange: (e) => handleInputChange('banner_text', e.target.value),
                                            })}

                                        />
                                        {errors.banner_text && <span className='err_msg'>Please enter Banner Text </span>}
                                    </Form.Group>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Banner Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('banner_image', {
                                                required: true,
                                                onChange: (e) => handleInputChange('banner_image', e.target.files[0]),
                                            })}


                                        />
                                        {errors.banner_image && <span className='err_msg'>Please select Banner Image </span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            <img src={formData.banner_image ? URL.createObjectURL(formData.banner_image) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                        </div>
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Canonical</Form.Label>
                                        <Form.Control type="text"

                                            value={formData.canonical || ''}
                                            {...register('canonical', {
                                                required: false,
                                                onChange: (e) => handleInputChange('canonical', e.target.value),
                                            })}

                                        />

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Title</Form.Label>
                                        <Form.Control type="text"
                                            value={formData.seo_title || ''}
                                            {...register('seo_title', {
                                                required: true,
                                                onChange: (e) => handleInputChange('seo_title', e.target.value),
                                            })}

                                        />
                                        {errors.seo_title && <span className='err_msg'>Please enter Seo Title </span>}
                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Keywords</Form.Label>
                                        <Form.Control type="text"
                                            value={formData.seo_keywords || ''}
                                            {...register('seo_keywords', {
                                                required: false,
                                                onChange: (e) => handleInputChange('seo_keywords', e.target.value),
                                            })}
                                        // onChange={(e) => setSeoKeyword(e.target.value)}

                                        />
                                    </Form.Group>
                                    <Form.Group className=" col-md-6 pb-5">
                                        <Form.Label>SEO Follow</Form.Label>
                                        <Form.Select value={formData.seo_follow || ''} onChange={(e) => handleInputChange('seo_follow', e.target.value)}>
                                            <option value="1">Index Follow</option>
                                            <option value="2">Index No-follow</option>
                                            <option value="3">No-index Follow</option>
                                            <option value="4">No-index No-follow</option>
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>SEO Script</Form.Label>
                                        <Form.Control type="text"
                                            value={formData.seo_script || ''}
                                            onChange={(e) => handleInputChange('seo_script', e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="col-md-12 pb-5">
                                        <Form.Label>SEO Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="seo_description"
                                            value={formData.seo_description || ''}
                                            onChange={(e) => handleInputChange('seo_description', e.target.value)}
                                        />
                                    </Form.Group>

                                    <div className='text-center'>
                                        <Button disabled={pending ? true : false} className='Add_btn_' type='submit'>
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </div>


                        </div>
                    </div>
                </section>
            </section>


        </>

    )
}

export default AddSubService;