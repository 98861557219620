
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DataTables from "../DataTable/DataTables";
import { Form } from "react-bootstrap";


const Customers = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [pending, setPending] = useState(true);

    const getData = () => {

        axios.get('/api/allUsers').then((response) => {
            if (response.status === 200) {
                setData(response.data.data);
                setNewData(response.data.data);
                setPending(false);
            }
        }).catch((err) => {
            console.log(err)
        })

    }

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        setData(newData?.filter(data => data.status == 0));

    }, [newData]);


    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.get('/api/userStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                window.location.reload(false);

            }
        }).catch((err) => {

            console.log(err);
        })
    }

    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }


    const tableData = {
        columns: [
            {
                name: "#",
                width: '80px',
                cell: (row, index) => index + 1,
            },
            {
                name: "Name",
                selector: "name"
                // width: '200px',

            },
            {
                name: "Email",
                selector: "email"
                // width: '200px',

            },
            {
                name: "Phone",
                selector: "phone"
                // width: '200px',

            },
            {
                name: "Address",
                selector: "address"
                // width: '200px',

            },
            {
                name: "Status",
                width: '250px',
                selector: row =>

                    <select className=" col-md-4 select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
                        value={row.status}
                        id={row.id}
                        onChange={localStorage.getItem("editAuth") == 0 ? (e) => handleStatus(e) : null}
                    >
                        <option value="0">Active</option>
                        <option value="1">In-Active</option>

                    </select>
                ,
                center: true,



            },

            // {
            //     name: "Action",
            //     cell: (row) => (
            //         <Button className="_delete_btn_" >
            //             <FaTrashAlt />
            //         </Button>
            //     ),
            //     right: true,
            // },
        ],
    };

    return (
        <>


            <Helmet>

                <title>Blue Cleaning Group | Customers</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                {/* <ToastContainer /> */}
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className="p-3">Customers</span>
                            <section className="card m-3">

                                <div className="selectoption mt-4">
                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0" selected>Active</option>
                                        <option value="1">In-Active</option>
                                    </select>
                                </div>

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Customers" />
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>

        </>
    )
}

export default Customers