import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const UpdateSeo = () => {

    const navigate = useNavigate();

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [canonical, setCanonical] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoFollow, setSeoFollow] = useState('');


    const getData = () => {

        axios.get(`/api/singleSeo/${id}`).then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        })
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        data?.map((seo) => {
            setSeoTitle(seo.seo_title);
            setCanonical(seo.canonical);
            setSeoDescription(seo.seo_description);
            setSeoFollow(seo.seo_follow);
            setSeoKeyword(seo.seo_keywords);
            setSeoScript(seo.seo_script);
        })

    }, [data]);


    const notify = () => toast.info("Seo Updated SuccessFully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = () => {

        axios.get('/api/updateSeo', {
            id : id,
            seo_title: seoTitle,
            seo_description: seoDescription,
            seo_keywords: seoKeyword,
            seo_follow: seoFollow,
            canonical: canonical,
            seo_script: seoScript

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                navigate('/admin/seo');
            }

        }).catch((err) => {

            console.log(err);
        })



    }

    return (
        <>

<div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Update Seo</span>
                            <div className='p-3'>
                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>

                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Title</Form.Label>
                                        <Form.Control type="text"
                                            value={seoTitle}
                                            {...register('seoTitle', {
                                                required: false,
                                                onChange: (e) => setSeoTitle(e.target.value),
                                            })}

                                        />
                                        {errors.seoTitle && <span className='err_msg'>Please enter Seo Title </span>}
                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Keywords</Form.Label>
                                        <Form.Control type="text"
                                            value={seoKeyword}
                                            {...register('seoSeoKeyword', {
                                                required: false,
                                                onChange: (e) => setSeoKeyword(e.target.value),
                                            })}
                                        // onChange={(e) => setSeoKeyword(e.target.value)}

                                        />
                                        {errors.seoSeoKeyword && <span className='err_msg'>Please enter Seo Keyword </span>}
                                    </Form.Group>
                                    <Form.Group className=" col-md-6 pb-5">
                                        <Form.Label>SEO Follow</Form.Label>
                                        <Form.Select value={seoFollow}
                                            onChange={(e) => setSeoFollow(e.target.value)}>
                                            <option value="1">Index Follow</option>
                                            <option value="2">Index No-follow</option>
                                            <option value="3">No-index Follow</option>
                                            <option value="4">No-index No-follow</option>
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>SEO Script</Form.Label>
                                        <Form.Control type="text"
                                            value={seoScript}
                                            {...register('seoScript', {
                                                required: false,
                                                onChange: (e) => setSeoScript(e.target.value),
                                            })}

                                        />
                                        {errors.seoScript && <span className='err_msg'>Please enter Seo Script </span>}
                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Canonical</Form.Label>
                                        <Form.Control type="text"

                                            value={canonical}
                                            {...register('seoCanonical', {
                                                required: false,
                                                onChange: (e) => setCanonical(e.target.value),
                                            })}

                                        />

                                    </Form.Group>

                                    <Form.Group className="col-md-12 pb-5">
                                        <Form.Label>SEO Description</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                            value={seoDescription}
                                            {...register('seoDes', {
                                                required: false,
                                                onChange: (e) => setSeoDescription(e.target.value),
                                            })}
                                        />
                                        {errors.seoDes && <span className='err_msg'>Please enter Seo Description </span>}
                                    </Form.Group>

                                    <div>
                                        <Button disabled={pending ? true : false} className='Add_btn_ m-3' type='submit'>
                                            Submit
                                        </Button>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default UpdateSeo