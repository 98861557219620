import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BookingData from './BookingData';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { color } from 'framer-motion';

const MyBookings = () => {


    const [booking, setBooking] = useState([]);
    const [myspinner, setMyspinner] = useState(false);
    const [show, setShow] = useState(false);
    const [cancelId, setCancelId] = useState('');

    const getData = () => {
        axios.get('/api/getUserBooking', { email: localStorage.getItem("auth1") }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setBooking(response.data.data);
            setMyspinner(false);
        }).catch(err => {
            console.log(err)
            setMyspinner(false);
        })
    }


    useEffect(() => {
        getData();
        setMyspinner(true);
    }, [])



    const handleShow = (id) => {
        setShow(true);
        setCancelId(id);
    };

    const handleClose = () => {
        setShow(false);
        setCancelId('');

    };


    const cancelReqSend = () =>
        toast.info("Your Request sent Successfully", { position: "top-center" });

    // booking cancel 
    const handleCancel = () => {
        axios.get('/api/userBookingCancel', {
            id: cancelId,
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                cancelReqSend();
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            }

        }).catch(err => {
            console.log(err)
        })
    }



    const options = [
        { value: 2, label: 'Monday', },
        { value: 3, label: 'Tuesday' },
        { value: 4, label: 'Wednesday' },
        { value: 5, label: 'Thursday' },
        { value: 6, label: 'Friday' },
        { value: 7, label: 'Saturday' },
        { value: 1, label: 'Sunday' }
    ]



    const tableData = {
        columns: [
            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>#</h6></>,
                width: '50px',
                cell: (row, index) => index + 1
            },

            // bookingCancel
            // {
            //     name: <></>,
            //     selector: row => <>
            //         {row.cancel_btn === "show" ?
            //             <Button className='btn-danger _cancel' onClick={() => handleShow(row.id)}>Cancel</Button>
            //             : null}
            //     </>
            // },

            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Service Name</h6></>,
                selector: row => <> <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(e) => <Tooltip id="button-tooltip" {...e}>
                        {row.sub_service_id}
                    </Tooltip>}
                >
                    <p className='m-0'>{row.sub_service_id}</p>
                </OverlayTrigger></>,
                 center: true,
                //  width: 'fit-content',
                // sortable: true

            },
            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Service Data</h6></>,
                selector: "start_date",
                // width: 'fit-content',
                // sortable: true

            },
            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Booking Id</h6></>,
                selector: "booking_id",
                center: true,
                // width: 'fit-content',
                // sortable: true

            },
            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Booking Status</h6></>,
                selector: row => <span style={row.booking_status == "Pending" ? {color: "#DBA11C", fontWeight: 'bold'} : row.booking_status == "Confirmed" ? {color: '#146FB7', fontWeight: 'bold'} : row.booking_status == "Completed" ? {color: 'green', fontWeight: 'bold'} : {color: 'red', fontWeight: 'bold'}}>{row.booking_status}</span>,
                center: true,
                // width: '100px',
                // sortable: true

            },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Payment Type</h6></>,
            //     selector: "payment_type",
            //     // width: 'fit-content',
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Payment Status</h6></>,
            //     selector: "payment_status",
            //     // sortable: true

            // },

            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Transaction Id</h6></>,
            //     selector: "transaction_id",
            //     // width: 'fit-content',
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Email</h6></>,
            //     selector: "email",

            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Sub Service List</h6></>,
            //     selector: row => <> <OverlayTrigger
            //         placement="bottom"
            //         delay={{ show: 250, hide: 400 }}
            //         overlay={(e) => <Tooltip id="button-tooltip" {...e}>
            //             {row.sub_service_list_id}
            //         </Tooltip>}
            //     >
            //         <p className='m-0'>{row.sub_service_list_id}</p>
            //     </OverlayTrigger></>,
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Total Bedroom</h6></>,
            //     selector: "total_bedroom",
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Total Bathroom</h6></>,
            //     selector: "total_bathroom",
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Clean Schedule</h6></>,
            //     selector: "clean_schedule",
            //     // sortable: true

            // },

            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Start Date</h6></>,
            //     selector: "start_date",
            //     // sortable: true


            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>End Date</h6></>,
            //     selector: "end_date",
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Service Days</h6></>,
            //     cell: row => row.service_days,

            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Time</h6></>,
            //     selector: "time",
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Hour</h6></>,
            //     selector: "hour",
            //     // sortable: true

            // },

            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Final Price</h6></>,
            //     selector: "final_price",
            //     // sortable: true

            // },

            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Total Price</h6></>,
            //     selector: "total_price",
            //     // sortable: true

            // },
            // {
            //     name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Paid Amount</h6></>,
            //     selector: "paid_amount",
            //     // sortable: true

            // },


            // {
            //     name: "status",
            //     cell: row => <select className="select_status">
            //         <option selected disabled>Status</option>
            //         <option value="0">Pending</option>
            //         <option value="1">Completed</option>
            //     </select>


            // },
            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Cancel Status</h6></>,
                selector: row => <span style={row.cancel_status == "Initiated" ? {color: "#DBA11C", fontWeight: 'bold'} : row.cancel_status == "Accepted" ? {color: 'green', fontWeight: 'bold'} : row.cancel_status == "Rejected" ? {color: 'red', fontWeight: 'bold'} : {color: '#146FB7', fontWeight: 'bold'}}>{row.cancel_status}</span>,
                center: true,
                // width: '100px',
                // centered: true

            },
            {
                name: <><h6 fontSize="16px" style={{ color: '#146FB7', whiteSpace: 'nowrap' }}>Action(View/Cancel)</h6></>,
                cell: row => <div className='d-flex align-align-items-center' style={{ whiteSpace: 'nowrap' }} >
                    <Link to={`/booking/${row.booking_id}`}><Button className='submit_btn_ m-2' >View</Button></Link>
                    {row.cancel_btn === "hide" || row.booking_status === "Cancel" || row.cancel_by != 0 ? null
                        : <Button className='m-2 btn-danger _cancel' onClick={() => handleShow(row.id)}>Cancel</Button>
                    }
                </div>,
                left: true,
            },
            // {
            //     name: <></>,
            //     selector: row => <>
            //         {row.cancel_btn === "hide" && row.booking_status === "Cancel" ? null
            //             : <Button className='btn-danger _cancel' onClick={() => handleShow(row.id)}>Cancel</Button>
            //         }
            //     </>
            // },

        ],

    };


    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | Profile</title>

            </Helmet>


            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>


            <section className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <div className='container-fluid mt-5 mb-5' style={{ minHeight: '400px' }}>
                    {/* <div className='d-flex justify-content-between'> */}
                    <h3 className='pb-4'>My Bookings</h3>
                    {/* <div>
                            <Button>Pay Now</Button>
                            <Button>Cancel Booking</Button>
                        </div> */}
                    {/* </div> */}

                    <BookingData data={booking} columns={tableData.columns} />
                </div>
            </section>




            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Cancel Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure Do You want to cancel your Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button className='_cancel' variant='danger' onClick={() => handleCancel()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default MyBookings