import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Success = () => {

    const { id } = useParams();

    const [bookingDetails, setbookingDetails] = useState([]);

    const getUser = () => {
        axios.get('/api/getSuccessData', { booking_id: id }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setbookingDetails(response.data.data);
        }).catch(err => {
            console.log(err)
        });


    }

    useEffect(() => {
        getUser();
        window.scrollTo(0, 0);
    }, [id]);

    return (
        <>

            <Card className='m-auto text-center' style={{ width: '18rem', border: "none" }}>
                <Card.Body>
                    <div class="main-container">
                        <div class="check-container">
                            <div class="check-background">
                                <svg viewBox="0 0 65 51" fill="none" xmlns="">
                                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div class="check-shadow"></div>
                        </div>
                    </div>
                    <Card.Title style={{ color: '#146FB7', fontSize: '1.5rem' }}>Booking Successful</Card.Title>
                </Card.Body>
            </Card>


            <section className="container-fluid">
                <div className="container _success_d m-auto">
                    {bookingDetails?.map((Booking) => {
                        return (
                            <>

                                {/* <section className="card">
                                    <div className='card-body'>
                                        <h5>Booking Details</h5>
                                        <Table borderless >
                                            <tbody>


                                              

                                            </tbody>
                                        
                                        </Table>
                                    </div>
                                </section> */}

                                <section className="card">
                                    <div className='card-body'>
                                        <h5>Service Details</h5>
                                        <Table borderless >

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Booking Id</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.booking_id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Booking Status</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.booking_status == 0 ? "Pending" : Booking.booking_status == 1 ? "Confirm" : "Complete"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Service Name</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.sub_service_id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Service Type</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.sub_service_type}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Services</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.sub_service_list_id}
                                                    </td>
                                                </tr>
                                                {Booking.all_rooms_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Rooms Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.all_rooms_opt}
                                                        </td>
                                                    </tr> : null}

                                                {Booking.kitchen_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Kitchen Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.kitchen_opt}
                                                        </td>
                                                    </tr> : null}

                                                {Booking.living_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Living Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.living_opt}
                                                        </td>
                                                    </tr> : null}

                                                {Booking.bathroom_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Laundry Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.bathroom_opt}
                                                        </td>
                                                    </tr> : null}


                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Start Date</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.start_date}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Time</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.time}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Hour</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.hour}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <span>Regular Cleaning</span>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.clean_schedule}
                                                            </td>
                                                        </tr> */}

                                                {Booking.clean_schedule == 0 ? null : <>
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Regular Cleaning</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.clean_schedule == 1 ? "Weekly or Fortnightly" : Booking.clean_schedule == 2 ? "Aged Care / Disability" : Booking.clean_schedule == 3 ? "Move Out / Spring Clea" : "No"}
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Property Type</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.prop_type == 1 ? "Home" : Booking.prop_type == 2 ? "apartment" : "-"}
                                                        </td>
                                                    </tr> */}
                                                </>}
                                                {Booking.prop_type ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <strong>Property Type</strong>
                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.prop_type == 1 ? "Home" : Booking.prop_type == 2 ? "apartment" : "-"}
                                                        </td>
                                                    </tr> : null}
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Service Days</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.service_days}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Flexible With Date</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.date_confirm}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Special Instruction</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.special_instruction}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Requirement</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.requirement}
                                                    </td>
                                                </tr>

                                                {Booking.sub_service_type == "Hourly" ?
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <span>Bedroom Information</span>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.total_bedroom}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <span>Bathroom Information</span>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.total_bathroom}
                                                            </td>
                                                        </tr>
                                                    </>
                                                    : null}


                                            </tbody>

                                        </Table>
                                    </div>
                                </section>

                                <section className="card">
                                    <div className='card-body'>
                                        <h5>Personal Details</h5>
                                        <Table borderless >

                                            <tbody>

                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Name</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Email</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Phone</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Address</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.address}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Postcode</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.postcode}
                                                    </td>
                                                </tr>
                                                {/* postcode */}
                                            </tbody>

                                        </Table>
                                    </div>
                                    <div className='card-body'>
                                        <h5>Payment Details</h5>
                                        <Table borderless >
                                            {/* {bookingData?.map((Booking) => {
                                                        return ( */}
                                            <tbody>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Sub Total Price</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.total_price - Booking.gst_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>GST {Booking.gst}%</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.gst_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Total Price</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.total_price}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Paid Amount</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.paid_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Remaining Amount</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    ${Booking.total_price - Booking.paid_amount}
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Payment Type</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.payment_type == 0 ? "Paypal" : "Pay on arrival/Cash"}
                                                    </td>
                                                </tr>

                                                {Booking.payment_type == 0 ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Transaction Id</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.transaction_id}
                                                        </td>
                                                    </tr> : null}
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Payment Status</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.payment_status == 0 ? "Pending" : "Complete"}
                                                    </td>
                                                </tr>

                                            </tbody>
                                            {/* )
                                                    })} */}
                                        </Table>
                                    </div>
                                </section>
                            </>
                        )
                    })}
                </div>
                <center>
                    <Link to="/services/1"><Button className="mt-4 btn-primary">Explore more services</Button></Link>
                </center>
            </section>






        </>
    )
}

export default Success