import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { HiRefresh } from 'react-icons/hi';
import { FaShare, FaEdit, FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const ServicesAdmin = () => {

    const [file, setFile] = useState('');
    const [icon, setIcon] = useState('');
    const [banner, setBanner] = useState('');
    const [data1, setData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get('/api/getAdminService').then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const tableData = {
        columns: [
            {
                name: '#',
                // width: '50px',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: "name",
                // width: '200px',
                sortable: true
            },
            {
                name: "Image",
                selector: (row) => <img style={{ width: '50px', height: '50px', padding: '10px' }} src={`assets/service/${row.image}`} />,
                // width: '200px',
                sortable: true
            },
            {
                name: "Icon",
                selector: (row) => <img style={{ width: '50px', height: '50px', padding: '10px', backgroundColor: '#DBA11C' }} src={`assets/service/${row.icon}`} />,
                // width: '200px',
                sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
            {
                name: "Banner Image",
                selector: (row) => <img style={{ width: '50px', height: '50px', padding: '10px' }} src={`assets/service/${row.banner_image}`} />,
                // width: '200px',
                sortable: true
            },
            {
                name: "Banner Text",
                selector: "banner_text",
                // width: '100px',
                sortable: true
            },
            {
                name: "Action",
                cell: row => <div className='d-flex' style={{ gap: '10px' }}>
                    {localStorage.getItem("editAuth") == 0 ? <Link to={`/admin/updateservice/${row.id}`}><Button className='_edit_icons'><FaEdit /></Button></Link> : null}
                    {/* <Button className='_delete_btn_'><FaTrashAlt /></Button> */}
                </div>,
                right: true
            }


        ],

    };


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    }


    return (
        <>


            <Helmet>

                <title>Blue Cleaning Group | Services</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Services</span>

                            <section className="card _service_card m-3">

                                <div className="d-flex justify-content-between">

                                    {/* <div className="selectoption mt-4">
                                        <strong >Status :-</strong>
                                        <select className="status filter"

                                        // onChange={(e) => filterByStatus(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="0">Pending</option>
                                            <option value="1">Completed</option>
                                        </select>
                                    </div> */}

                                </div>
                                <div className="main">
                                    <DataTables data={data1} columns={tableData.columns} title="Service" />

                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>
        </>
    )
}

export default ServicesAdmin;