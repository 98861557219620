import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import CallIcon from '../assets/Assets/contact-min.png';
import LocaionIcon from '../assets/Assets/location-min.png';
import MailICon from '../assets/Assets/mail-min.png';
import Form from 'react-bootstrap/Form';
// import SendImg from '../assets/Assets/send-min.png';
import Ratio from 'react-bootstrap/Ratio';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';
import { Helmet } from 'react-helmet';
import { Button, Skeleton } from '@mui/material';
import { motion } from 'framer-motion';
import { fixedContainer, textVariants } from '../common/motion/motion';


const ContactUs = () => {


    const [contactInfo, setContactInfo] = useState([]);
    const [myspinner, setMyspinner] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [service, setService] = useState([]);
    const [msg, setMsg] = useState('');
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');


    const mapImg = contactInfo?.map((map) => map.map);

    const getAllData = () => {

        axios.get('/api/getSeo/4').then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })

        axios.get('/api/getLogo').then((response) => {
            if (response.status === 200) {
                setContactInfo(response.data.data);
                console.log(response.data.data);
                setMyspinner(false)
            }
        });

        axios.get('/api/allsubservice').then((response) => {
            setService(response.data.data)
            console.log(response.data.data)
        })

    }


    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);


    useEffect(() => {
        window.scrollTo(0, 0);
        getAllData();
        setMyspinner(true)
    }, []);



    const submitSuccess = () => toast.success("From Submit Successfully!",
        { position: "top-center", }
    );

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = () => {
        axios.get('/api/enquiry', { name: name, email: email, sub_service_id: serviceId, message: msg, phone: phone, }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                submitSuccess();
                setName('');
                setPhone('');
                setEmail('');
                resetSelectField();
                setMsg('');
                setServiceId('')
            }

        })

    }

    const onSelect = (e) => {
        let subServiceId = e?.map((id => id.id)).toString();
        setServiceId(subServiceId)
    }

    const onRemove = (e) => {
        let subServiceId = e?.map((id => id.id)).toString();
        setServiceId(subServiceId)
    }

    const multiselectRef = useRef();

    const resetSelectField = () => {
        multiselectRef.current.resetSelectedValues();
    };



    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta name="keywords" content={seoKeyword} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="hweindia" />
                <meta property="og:url" content={canonical} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta name="robots" content={robot} />
                <script>{seoScript}</script>

            </Helmet>

            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <div style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section className='fix_width'>
                    <div className='container-fluid'>
                        {myspinner ?
                            <>
                                <div className='row p-5'>
                                    <div className='col-4'>
                                        <Skeleton
                                            variant="rectangular"
                                            height="40vh"
                                            animation="wave"
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <Skeleton
                                            variant="rectangular"

                                            height="40vh"
                                            animation="wave"
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <Skeleton
                                            variant="rectangular"

                                            height="40vh"
                                            animation="wave"
                                        />
                                    </div>
                                    <div className='row p-5'>
                                        <Skeleton width="40%" />
                                        <Skeleton width="60%" />
                                        <Skeleton />
                                    </div>
                                </div>
                            </>
                            :
                            (contactInfo?.map((contact) => {
                                return (
                                    <div className='contact_us'>
                                        <div className='card_div'>
                                            <a target="_blank" href={contact.map}>
                                                <Card>
                                                    <img src={LocaionIcon} />
                                                    <span>{contact.address}</span>
                                                </Card>
                                            </a>

                                            {/* </a> */}
                                        </div>
                                        <div className='card_div'>
                                            <a href={`tel:${contact.phone}`}>
                                                <Card>
                                                    <img src={CallIcon} />
                                                    <span>{contact.phone}</span>
                                                </Card>
                                            </a>

                                        </div>
                                        <div className='card_div'>
                                            <a href={`mailto:${contact.email}`}>
                                                <Card>
                                                    <img src={MailICon} />
                                                    <span>{contact.email}</span>
                                                </Card>
                                            </a>

                                        </div>
                                    </div>

                                )
                            }))
                        }
                        <div className='contact_div row'>
                            <div className='col-lg-5'>
                                <div style={{ height: '100%' }}>
                                    <Ratio aspectRatio="4x3" className='h-100'>
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d104250.17001275856!2d149.130996!3d-35.260788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2f8a3bd0c7c17e1e!2sBlue%20Cleaning%20Group%20Pty%20Ltd!5e0!3m2!1sen!2sin!4v1668227509245!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                        <embed type="image/svg+xml" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d104250.17001275856!2d149.130996!3d-35.260788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2f8a3bd0c7c17e1e!2sBlue%20Cleaning%20Group%20Pty%20Ltd!5e0!3m2!1sen!2sin!4v1668227509245!5m2!1sen!2sin" />
                                    </Ratio>
                                </div>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29142.3940224542!2d75.04614681707191!3d24.073372903576683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39642ea9db15e09f%3A0x89a3e5ea4399695b!2sMandsaur%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1667296990724!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                            <div className='col-lg-7'>
                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12  mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter name" value={name}
                                            {...register('name', {
                                                required: true, maxLength: 30,
                                                onChange: (e) => setName(e.target.value),
                                            })}
                                        />
                                        {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Your Name</span>}
                                    </Form.Group>

                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="number" placeholder="Enter Phone Number" maxLength={15}
                                            {...register('phone', {
                                                required: true,
                                                maxLength: 15,
                                                minLength: 10,
                                                required: "Please Enter Your Phone Number",
                                                // pattern: {
                                                //     value: /^[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{5,10}$/,
                                                //     message: "Minimum 10 digit required"
                                                // },
                                                onChange: (e) => setPhone(e.target.value),
                                            })}
                                            value={phone}
                                        />
                                        {errors.phone && errors.phone.type === "maxLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                                        {errors.phone && errors.phone.type === "minLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                                        {errors.phone && errors.phone.type === "required" && <span className='err_msg'>Please Enter Your Phone Number</span>}

                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <Form.Label>Email Id</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={email}
                                            {...register('email', {
                                                required: true, maxLength: 30,
                                                onChange: (e) => setEmail(e.target.value),
                                            })}
                                        />
                                        {errors.email && errors.email.type === "required" && <span className='err_msg'>Please Enter email Address</span>}
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <Form.Label>Cleaning Requirements</Form.Label>

                                        <Multiselect
                                            className='multi_services'
                                            displayValue="name"
                                            hidePlaceholder={true}
                                            onKeyPressFn={function noRefCheck() { }}
                                            onRemove={onRemove}
                                            onSearch={function noRefCheck() { }}
                                            onSelect={onSelect}
                                            options={service}
                                            ref={multiselectRef}

                                        />

                                    </Form.Group>
                                    <Form.Group className="col-12 mb-3">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                            value={msg}
                                            {...register('message', {
                                                required: true,
                                                onChange: (e) => setMsg(e.target.value)
                                            })}
                                        />
                                        {errors.message && errors.message.type === "required" && <span className='err_msg'>Please Enter Your Message</span>}
                                    </Form.Group>
                                    <div className=''>
                                        <Button className='btn appointment_btn_yellow m-auto'
                                            type='submit'
                                            style={{ borderRadius: '0' }} ><span>Send Enquiry</span></Button>
                                    </div>
                                    {/* <img src={SendImg} style={{ width: '25px' }} /> */}

                                </Form>

                            </div>



                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default ContactUs