import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import TextEditor from '../Editor/TextEditor';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { FaShare, FaEdit, FaTrashAlt } from "react-icons/fa";
import DataTables from '../DataTable/DataTables';
import NoDataFound from '../Assets/Home Page Assets/noimage.jpg'
const UpdateSubService = () => {


  const { id } = useParams();

  const [id1, setId] = useState('');
  const [data, setData] = useState([]);
  const [newService, setNewService] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceId, setServiceId] = useState('');
  const [name, setName] = useState('');
  const [name1, setName1] = useState('');
  const [image, setImage] = useState('');
  const [pending, setPending] = useState(true);
  const [subService, setSubService] = useState([]);
  const [robot, setRobot] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [bannerUpdate, setBannerUpdate] = useState('');
  const [bannerText, setBannerText] = useState('');
  const [icon, setIcon] = useState('');
  const [updateIcon, setUpdateIcon] = useState('');
  const [subServiceType, setSubServiceType] = useState('');
  const [extra, setExtra] = useState('');
  const [initialPrice, setInitialPrice] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [minHour, setMinHour] = useState('');
  const [minHourPrice, setMinHourPrice] = useState('');
  const [additionalPrice, setAdditionalPrice] = useState('');
  // const [advanceType, setAdvanceType] = useState('');
  // const [advanceAmount, setAdvanceAmount] = useState('');
  const [regularCleaning, setRegularCleaning] = useState('');
  const [canonical, setCanonical] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoFollow, setSeoFollow] = useState('');
  const [content, setContent] = useState('')
  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [gst, setGst] = useState('');
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [singleData, setSingleData] = useState([]);
  const [subServiceList, setSubServiceList] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);
  const [subServiceListType, setSubServiceListType] = useState('');
  const [extraImage, setExtraImage] = useState('');
  const [subServices, setSubServices] = useState([]);
  const [subNewServices, setNewSubServices] = useState([]);
  const [service, setService] = useState([]);
  const [subServiceId, setSubServiceId] = useState('');
  const [price, setPrice] = useState('');
  const [selectedSubService, setSelectedSubService] = useState('');
  const [updateSelectedType, setUpdateSelectedType] = useState('');
  const [updatedExtra, setUpdatedExtra] = useState('');
  const [updateSelectedSubId, setUpdateSelectedSubId] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updatePrice, setUpdatePrice] = useState('');
  const [updateSelectedSub, setUpdateSelectedSub] = useState('');
  const [extraImg, setExtraImg] = useState('');
  const [combinations, setCombinations] = useState([]);
  const [subservice_comb_id, setSubservicecombid] = useState('');
  const [formDataAdd, setFormDataAdd] = useState({
    id: '',
    sub_service_id: '',
    sub_service_list_type: '',
    name: '',
    price: '',
    selected_sub_service: '',
    comb1_name: '',
    comb2_name: '',
    // extra_icon: '',
    comb1_qty: '',
    comb2_qty: '',
    comb_price: '',
    service_id: '',

  })
  function handleInputChange2(formField, formValue) {
    setFormDataAdd(prevFormData => ({
      ...prevFormData,
      [formField]: formValue
    }));
  }
  const handleAddSection = () => {
    // Create a new section with a unique ID
    const newSection = {
      id: Date.now(),
      comb1_qty: '',
      comb2_qty: '',
      price: '',

    };

    // Add the new section to the existing sections
    setCombinations((prevSections) => [...prevSections, newSection]);
  };
  const handleRemoveSection = (idToRemove) => {
    // Remove the section with the provided ID from the sections array
    setCombinations((prevSections) =>
      prevSections.filter((section) => section.id !== idToRemove)
    );
  };

  const handleInputChange1 = (id, field, event) => {
    // Update the state with the new value for the specified field in the section with the provided ID
    const { value } = event.target;
    setCombinations((prevSections) =>
      prevSections?.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      )
    );
  };

  const [allImage, setAllImage] = useState({
    image_one: '',
    image_two: '',
    image_three: '',
    banner_image: '',
  });

  const [formData, setFormData] = useState({
    subservice_comb_id: '',
    id_one: '',
    service_id_one: '1',
    type_one: '',
    extras_one: '0',
    initial_price_one: '',
    min_price_one: '',
    min_hour_one: '',
    min_hour_price_one: '',
    adtnl_hour_price_one: '',
    regular_service_one: '',
    prop_typeask_one: '',
    content_one: '',

    gst_one: '',

    service_id_two: '2',
    id_two: '',
    type_two: '',
    extras_two: '0',
    initial_price_two: '',
    min_price_two: '',
    min_hour_two: '',
    min_hour_price_two: '',
    adtnl_hour_price_two: '',
    regular_service_two: '',
    prop_typeask_two: '',
    content_two: '',

    gst_two: '',

    service_id_three: '3',
    id_three: '',
    type_three: '',
    extras_three: '0',
    initial_price_three: '',
    min_price_three: '',
    min_hour_three: '',
    min_hour_price_three: '',
    adtnl_hour_price_three: '',
    regular_service_three: '',
    prop_typeask_three: '',
    content_three: '',

    gst_three: '',

    name: '',
    banner_text: '',

    canonical: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    seo_follow: '',
    seo_script: '',
  });

  const handleServiceListData = () => {


    singleData?.map((seo) => {
      console.log(seo.service_id);
      axios.get(`/api/getSubServiceById/${seo.service_id}`).then((response) => {
        setSubServices(response.data.data);
        console.log(response.data.data);
      });

      setFormDataAdd(prevFormData => ({
        ...prevFormData,
        ['sub_service_id']: seo.sub_service_id,
        ['service_id']: seo.service_id,
        ['sub_service_list_type']: seo.sub_service_list_type,
        ['name']: seo.name,
        ['id']: seo.id,
        ['price']: seo.price,
        ['selected_sub_service']: seo.selected_sub_service,
        ['comb1_name']: seo.comb1_name,
        ['comb2_name']: seo.comb2_name,
        ['comb1_qty']: seo.comb1_qty,
        ['comb2_qty']: seo.comb2_qty,
        ['comb_id']: seo.comb_id,

      }

      ));


      seo.combination?.map((seo1) => {

        setCombinations(prevFormData => [
          ...prevFormData,
          {
            ['id']: seo1.id,
            ['comb_price']: seo1.price,
            ['comb1_qty']: seo1.comb1_qty,
            ['comb2_qty']: seo1.comb2_qty,
            // console.log(seo1.id);

          }

        ]);
      });


    })
  }

  useEffect(() => {
    handleServiceListData();

  }, [singleData]);

  const navigate = useNavigate();


  const getAllData = () => {

    axios.get(`/api/singleSubservice/${id}`
    ).then((response) => {
      console.log(response.data.data)
      setSubService(response.data.data);
    });

    axios.get('/api/getServiceHeader').then((response) => {
      setServices(response.data.data);
      setNewService(response.data.data);
      setService(response.data.data);
      setPending(false);
    }).catch((err) => {
      console.log(err);
    });


    // axios.get(`/api/getSubServiceById/${serviceId}`
    //   ).then((response) => {
    //     setNewSubServices(response.data.data);
    //   });
  }

  useEffect(() => {
    if (serviceId != '') {
      axios.get(`/api/getSubServiceById/${serviceId}`
      ).then((response) => {
        setNewSubServices(response.data.data);
      });
    }

  }, [serviceId]);

  //   useEffect(() => {
  //     setServices(newService.filter(data => data.id == id));
  // }, [newService]);


  const handleSEO = () => {
    subService?.map((seo) => {
      // console.log(seo.id_two);


      setFormData(prevFormData => ({
        ...prevFormData,

        ['subservice_comb_id']: seo.subservice_comb_id,
        ['id_one']: seo.id_one,
        ['service_id_one']: '1',
        ['type_one']: seo.type_one,
        ['extras_one']: seo.extras_one,
        ['initial_price_one']: seo.initial_price_one,
        ['min_price_one']: seo.min_price_one,
        ['min_hour_one']: seo.min_hour_one,
        ['min_hour_price_one']: seo.min_hour_price_one,
        ['adtnl_hour_price_one']: seo.adtnl_hour_price_one,
        ['regular_service_one']: seo.regular_service_one,
        ['prop_typeask_one']: seo.prop_typeask_one,
        ['content_one']: seo.content_one,
        ['gst_one']: seo.gst_one,

        ['service_id_two']: '2',
        ['id_two']: seo.id_two,
        ['type_two']: seo.type_two,
        ['extras_two']: seo.extras_two,
        ['initial_price_two']: seo.initial_price_two,
        ['min_price_two']: seo.min_price_two,
        ['min_hour_two']: seo.min_hour_two,
        ['min_hour_price_two']: seo.min_hour_price_two,
        ['adtnl_hour_price_two']: seo.adtnl_hour_price_two,
        ['regular_service_two']: seo.regular_service_two,
        ['prop_typeask_two']: seo.prop_typeask_two,
        ['content_two']: seo.content_two,
        ['gst_two']: seo.gst_two,

        ['service_id_three']: '3',
        ['id_three']: seo.id_three,
        ['type_three']: seo.type_three,
        ['extras_three']: seo.extras_three,
        ['initial_price_three']: seo.initial_price_three,
        ['min_price_three']: seo.min_price_three,
        ['min_hour_three']: seo.min_hour_three,
        ['min_hour_price_three']: seo.min_hour_price_three,
        ['adtnl_hour_price_three']: seo.adtnl_hour_price_three,
        ['regular_service_three']: seo.regular_service_three,
        ['prop_typeask_three']: seo.prop_typeask_three,
        ['content_three']: seo.content_three,
        ['gst_three']: seo.gst_three,

        ['name']: seo.name,
        ['banner_text']: seo.banner_text,
        ['canonical']: seo.canonical,
        ['seo_title']: seo.seo_title,
        ['seo_script']: seo.seo_script,
        ['seo_description']: seo.seo_description,
        ['seo_follow']: seo.seo_follow,
        ['seo_keywords']: seo.seo_keywords,
      }

      ));

      setAllImage(prevFormData => ({
        ...prevFormData,
        ['image_one']: seo.image_one,

        ['image_two']: seo.image_two,

        ['image_three']: seo.image_three,

        ['banner_image']: seo.banner_image,
      }

      ));


    })
  }
  useEffect(() => {
    handleSEO();
  }, [subService]);


  useEffect(() => {
    window.scrollTo(0, 0);
    getAllData();

  }, [])


  function handleInputChange(formField, formValue) {
    setFormData(prevFormData => ({
      ...prevFormData,
      [formField]: formValue,
    }));
  }



  const handleEditorChange = (contentField, content) => {
    setFormData((prevFormData) => ({ ...prevFormData, [contentField]: content }));
  };


  function getServiceData(type, type_) {
    if (type_ == 'type_one') {
      switch (type) {
        case '0':
          return (
            <>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Initial Price</Form.Label>
                <Form.Control type="number"
                  value={formData.initial_price_one || ''}
                  {...register4('initial_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('initial_price_one', e.target.value),
                  })}
                />
                {errors4.initial_price_one && <span className='err_msg'>Please enter Initial Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_one || ''}
                  {...register4('min_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_one', e.target.value),
                  })}
                />
                {errors4.min_price_one && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>
            </>
          )
        case '1':
          return (
            <>

            </>
          )
        case '2':
          return (
            <>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_one || ''}
                  {...register4('min_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_one', e.target.value),
                  })}
                />
                {errors4.min_price_one && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_one || ''}
                  {...register4('min_hour_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_one', e.target.value),
                  })}

                />
                {errors4.min_hour_one && <span className='err_msg'>Please enter minimum Hour </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_price_one || ''}
                  {...register4('min_hour_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_price_one', e.target.value),
                  })}
                />
                {errors4.min_hour_price_one && <span className='err_msg'>Please enter minimum Hour Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Additional Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.adtnl_hour_price_one}
                  {...register4('adtnl_hour_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('adtnl_hour_price_one', e.target.value),
                  })}
                />
                {errors4.adtnl_hour_price_one && <span className='err_msg'>Please enter Additional Hour Price </span>}
              </Form.Group>
            </>
          )
        case '3':
          return (
            <>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Initial Price</Form.Label>
                <Form.Control type="number"
                  value={formData.initial_price_one || ''}
                  {...register4('initial_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('initial_price_one', e.target.value),
                  })}
                />
                {errors4.initial_price_one && <span className='err_msg'>Please enter Initial Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_one || ''}
                  {...register4('min_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_one', e.target.value),
                  })}
                />
                {errors4.min_price_one && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>

              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_one || ''}
                  {...register4('min_hour_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_one', e.target.value),
                  })}

                />
                {errors4.min_hour_one && <span className='err_msg'>Please enter minimum Hour </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_price_one}
                  {...register4('min_hour_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_price_one', e.target.value),
                  })}
                />
                {errors4.min_hour_price_one && <span className='err_msg'>Please enter minimum Hour Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Additional Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.adtnl_hour_price_one}
                  {...register4('adtnl_hour_price_one', {
                    required: true,
                    onChange: (e) => handleInputChange('adtnl_hour_price_one', e.target.value),
                  })}
                />
                {errors4.adtnl_hour_price_one && <span className='err_msg'>Please enter Additional Hour Price </span>}
              </Form.Group>
            </>
          )
      }
    } else if (type_ == 'type_two') {
      switch (type) {
        case '0':
          return (
            <>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Initial Price</Form.Label>
                <Form.Control type="number"
                  value={formData.initial_price_two || ''}
                  {...register4('initial_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('initial_price_two', e.target.value),
                  })}
                />
                {errors4.initial_price_two && <span className='err_msg'>Please enter Initial Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_two || ''}
                  {...register4('min_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_two', e.target.value),
                  })}
                />
                {errors4.min_price_two && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>
            </>
          )
        case '1':
          return (
            <>

            </>
          )
        case '2':
          return (
            <>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_two || ''}
                  {...register4('min_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_two', e.target.value),
                  })}
                />
                {errors4.min_price_two && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_two || ''}
                  {...register4('min_hour_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_two', e.target.value),
                  })}

                />
                {errors4.min_hour_two && <span className='err_msg'>Please enter minimum Hour </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_price_two}
                  {...register4('min_hour_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_price_two', e.target.value),
                  })}
                />
                {errors4.min_hour_price_two && <span className='err_msg'>Please enter minimum Hour Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Additional Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.adtnl_hour_price_two}
                  {...register4('adtnl_hour_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('adtnl_hour_price_two', e.target.value),
                  })}
                />
                {errors4.adtnl_hour_price_two && <span className='err_msg'>Please enter Additional Hour Price </span>}
              </Form.Group>
            </>
          )
        case '3':
          return (
            <>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Initial Price</Form.Label>
                <Form.Control type="number"
                  value={formData.initial_price_two || ''}
                  {...register4('initial_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('initial_price_two', e.target.value),
                  })}
                />
                {errors4.initial_price_two && <span className='err_msg'>Please enter Initial Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_two || ''}
                  {...register4('min_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_two', e.target.value),
                  })}
                />
                {errors4.min_price_two && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>

              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_two || ''}
                  {...register4('min_hour_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_two', e.target.value),
                  })}

                />
                {errors4.min_hour_two && <span className='err_msg'>Please enter minimum Hour </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_price_two}
                  {...register4('min_hour_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_price_two', e.target.value),
                  })}
                />
                {errors4.min_hour_price_two && <span className='err_msg'>Please enter minimum Hour Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Additional Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.adtnl_hour_price_two}
                  {...register4('adtnl_hour_price_two', {
                    required: true,
                    onChange: (e) => handleInputChange('adtnl_hour_price_two', e.target.value),
                  })}
                />
                {errors4.adtnl_hour_price_two && <span className='err_msg'>Please enter Additional Hour Price </span>}
              </Form.Group>
            </>
          )
      }
    } else if (type_ == 'type_three') {
      switch (type) {
        case '0':
          return (
            <>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Initial Price</Form.Label>
                <Form.Control type="number"
                  value={formData.initial_price_three || ''}
                  {...register4('initial_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('initial_price_three', e.target.value),
                  })}
                />
                {errors.initial_price_three && <span className='err_msg'>Please enter Initial Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_three || ''}
                  {...register4('min_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_three', e.target.value),
                  })}
                />
                {errors4.min_price_three && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>
            </>
          )
        case '1':
          return (
            <>

            </>
          )
        case '2':
          return (
            <>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_three || ''}
                  {...register4('min_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_three', e.target.value),
                  })}
                />
                {errors4.min_price_three && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_three || ''}
                  {...register4('min_hour_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_three', e.target.value),
                  })}

                />
                {errors4.min_hour_three && <span className='err_msg'>Please enter minimum Hour </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_price_three}
                  {...register4('min_hour_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_price_three', e.target.value),
                  })}
                />
                {errors4.min_hour_price_three && <span className='err_msg'>Please enter minimum Hour Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Additional Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.adtnl_hour_price_three}
                  {...register4('adtnl_hour_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('adtnl_hour_price_three', e.target.value),
                  })}
                />
                {errors4.adtnl_hour_price_three && <span className='err_msg'>Please enter Additional Hour Price </span>}
              </Form.Group>
            </>
          )
        case '3':
          return (
            <>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Initial Price</Form.Label>
                <Form.Control type="number"
                  value={formData.initial_price_three || ''}
                  {...register4('initial_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('initial_price_three', e.target.value),
                  })}
                />
                {errors4.initial_price_three && <span className='err_msg'>Please enter Initial Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label>Min Order Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_price_three || ''}
                  {...register4('min_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_price_three', e.target.value),
                  })}
                />
                {errors4.min_price_three && <span className='err_msg'>Please enter Minimum Price </span>}
              </Form.Group>

              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_three || ''}
                  {...register4('min_hour_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_three', e.target.value),
                  })}

                />
                {errors4.min_hour_three && <span className='err_msg'>Please enter minimum Hour </span>}
              </Form.Group>
              <Form.Group className=" col-md-2 pb-5">
                <Form.Label> Min Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.min_hour_price_three}
                  {...register4('min_hour_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('min_hour_price_three', e.target.value),
                  })}
                />
                {errors4.min_hour_price_three && <span className='err_msg'>Please enter minimum Hour Price </span>}
              </Form.Group>
              <Form.Group className=" col-md-3 pb-5">
                <Form.Label>Additional Hour Price</Form.Label>
                <Form.Control type="number"
                  value={formData.adtnl_hour_price_three}
                  {...register4('adtnl_hour_price_three', {
                    required: true,
                    onChange: (e) => handleInputChange('adtnl_hour_price_three', e.target.value),
                  })}
                />
                {errors4.adtnl_hour_price_three && <span className='err_msg'>Please enter Additional Hour Price </span>}
              </Form.Group>
            </>
          )
      }
    }
  }

  const handleUpdateClose = () => {
    setShowUpdate(false);

    reset({
      name: "",
      image: "",
      price: "",
      subservice: "",
      selectedsubservice: ""
    });
    setUpdateSelectedType("");
    setUpdatedExtra("");
  }



  const handleClose = () => {
    setShow(false);
    setSubServiceListType('');
    setExtraImage("");
    reset({
      name: "",
      image: "",
      price: "",
      service: "",
      subservice: "",
      selectedsubservice: ""
    })
  }


  const getData = () => {
    axios.get(`/api/allSubServiceList/${id}`).then((response) => {
      setData(response.data.data);
      setPending(false);
      console.log(response.data.data);
    });


  }

  useEffect(() => {
    getData();
  }, []);



  const handleShowDelete = (id) => {
    setShowDelete(true);
    setDeleteId(id)
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteId('');
  }

  const handleServiceList = (id) => {
    setShowUpdate(true);

    setCombinations([]);
    axios.get(`/api/singleSubServiceList/${id}`).then((response) => {
      setSingleData(response.data.data);
    });
    axios.get('/api/subServiceName').then((response) => {
      setSubServiceList(response.data.data);
      console.log(response.data.data);
    });


  }

  const notify = () => toast.info("Sub Service Updated SuccessFully", { position: "top-center" });

  const { register, handleSubmit, formState: { errors } } = useForm();

  const { register: register4, handleSubmit: handleSubmit4, formState: { errors: errors4 } } = useForm();
  const onSubmit = (data) => {
    setPending(true);
    const formData1 = new FormData();
    formData1.append("image_one", formData);
    formData1.append("image_two", formData);
    formData1.append("image_three", formData);
    formData1.append("banner_image", formData);

    axios.get(`/api/updateSubService/${id}`, formData
      , {
        method: 'POST',
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }

    ).then((response) => {
      if (response.status === 200) {
        notify();
        setPending(false);
        navigate('/admin/subservice')

      }
    })

  }
  const handleShow = () => {
    setShow(true);
    setCombinations([{
      id: Date.now(),
      comb1_qty: '',
      comb2_qty: '',
      comb_price: '',
    }]);
  }

  const notifyStatus = () => toast.info("Status Updated SuccessFully", { position: "top-center" });

  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.get('/api/subServiceItemStatus', {
      id: id,
      status: status
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyStatus();
        window.location.reload(false);
      }

    }).catch((err) => {
      console.log(err);
    })
  }
  const tableData = {
    columns: [
      {
        name: "#",
        width: '80px',
        cell: (row, index) => index + 1,
      },
      {
        name: "Name",
        selector: "name",
        width: '300px',

      },

      {
        name: "Sub Service Name",
        selector: "sub_service_name",
        width: '300px',

      },
      {
        name: "Selected Sub Service",
        selector: "selected_sub_service",
        width: '300px',

      },
      {
        name: "sub_service_list_type",
        selector: "sub_service_list_type",
      },
      {
        name: "Extra",
        selector: (row) => (
          <>
            {row.extra_icon == "" ? "-" :
              <img
                width="50px"
                height="50px"
                className="p-2"
                src={`/api/assets/extras/${row.extra_icon}`}
              />
            }
          </>
        ),
        width: "100px",
        // sortable: true,
        // cell: d => <span>{d.genres.join(", ")}</span>
      },
      {
        name: "Price",
        selector: "price",
        width: "80px"
      },

      {
        name: "Status",
        cell: row => <select className="select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
          value={row.status} id={row.id}
          onChange={(e) => handleStatus(e)}
        >
          <option value="0">Active</option>
          <option value="1">In-Active</option>
        </select>
        // selector: (row) => ,


      },
      {
        name: "Action",
        cell: (row) => (
          <div className="d-flex" style={{ gap: "10px" }}>
            {localStorage.getItem("editAuth") == 0 ?
              <Button className="_edit_icons" onClick={() => handleServiceList(row.id)}>
                <FaEdit />
              </Button>
              : null}
            {localStorage.getItem("deleteAuth") == 0 ?
              <Button className="_delete_btn_" onClick={() => handleShowDelete(row.id)}>
                <FaTrashAlt />
              </Button>
              : null}
          </div>
        ),
        right: true,
      },
    ],
  };


  const handleSubServiceCombId = (e) => {
    let subService = subServices?.find(item => item.id == e.target.value);
    if (subService) {
      setSubservicecombid(subService.subservice_comb_id);
    }
  }


  const handleService = (e) => {
    let id = e.target.value;
    setServiceId(id);
    if (id != "") {
      axios.get(`/api/getSubServiceById/${id}`
      ).then((response) => {
        setSubServices(response.data.data);
        // setSubservicecombid(response.data.data[0].subservice_comb_id);
      });
    } else {
      setSubServices([]);
    }

  }


  useEffect(() => {
    singleData?.map((data) => {
      setId(data.id);
      setUpdateSelectedType(data.sub_service_list_type);
      setUpdateSelectedSubId(data.sub_service_id);
      // setUpdateSelectedServiceId(data.sub_service_id);
      setUpdatePrice(data.price);
      setUpdateSelectedSub(data.selected_sub_service);
      setUpdateName(data.name);
      setExtraImg(data.extra_icon);
    });

  }, [subServiceList, singleData])


  const notify2 = () => toast.info("Sub Service Item Added SuccessFully", { position: "top-center" });
  const notifyUpdate = () => toast.info("Sub Service Item Updated SuccessFully", { position: "top-center" });
  const notifyDelete = () => toast.info("Sub Service Item Deleted SuccessFully", { position: "top-center" });

  const { register: register2, handleSubmit: handleSubmit2, reset, formState: { errors: errors2 } } = useForm();



  const onSubmit2 = () => {
    // Create a new FormData instance and append the necessary data
    const formData = new FormData();
    formData.append("sub_service_id", formDataAdd.sub_service_id);
    formData.append("service_id", formDataAdd.service_id);
    formData.append("service_comb_id", subservice_comb_id);
    formData.append("sub_service_list_type", subServiceListType);
    formData.append("name", formDataAdd.name);
    formData.append("price", formDataAdd.price);
    formData.append("selected_sub_service", formDataAdd.selected_sub_service);
    formData.append("comb1_name", formDataAdd.comb1_name);
    formData.append("comb2_name", formDataAdd.comb2_name);
    formData.append("extra_icon", extraImage);
    formData.append("comb1_qty", combinations?.map((data) => data.comb1_qty).toString());
    formData.append("comb2_qty", combinations?.map((data) => data.comb2_qty).toString());
    formData.append("comb_price", combinations?.map((data) => data.comb_price).toString());


    axios
      .post("/addSubServiceList", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          notify();
          handleClose();
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();
  const onUpdate = () => {





    // updateSubServiceList

    const formData = new FormData();
    console.log(formDataAdd.id);
    formData.append("id", formDataAdd.id);
    formData.append("sub_service_id", formDataAdd.sub_service_id);
    formData.append("service_comb_id", subservice_comb_id);
    formData.append("sub_service_list_type", updateSelectedType);
    formData.append("name", formDataAdd.name);
    formData.append("price", formDataAdd.price);
    formData.append("selected_sub_service", formDataAdd.selected_sub_service);
    formData.append("comb1_name", formDataAdd.comb1_name);
    formData.append("comb2_name", formDataAdd.comb2_name);
    formData.append("extra_icon", updatedExtra);
    formData.append("comb1_qty", combinations?.map((data) => data.comb1_qty).toString());
    formData.append("comb2_qty", combinations?.map((data) => data.comb2_qty).toString());
    formData.append("comb_price", combinations?.map((data) => data.comb_price).toString());
    // formData.append("id", combinations?.map((data) => data.id).toString());
    formData.append("comb_id", formDataAdd.comb_id);

    axios
      .post("/updateSubServiceList", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          notifyUpdate();
          handleClose();
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

  }
  const handleDelete = () => {

    axios.get("/api/deleteSubServiceList",
      {
        id: deleteId
      },
      {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        handleCloseDelete();
        notifyDelete();
        window.location.reload(false);
      }
    }).catch((err) => {
      console.log(err);
    });


  }

  function getItemData(type) {
    switch (type) {
      case '0':
      case '1':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id || ''}
                {...register("service_id", {

                  required: true,
                })}
                onChange={(e) => {
                  handleService(e);
                  handleInputChange2('service_id', e.target.value);
                }}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}
                {...register("sub_service_id", {
                  onChange: (e) => {
                    handleInputChange2('sub_service_id', e.target.value);
                    handleSubServiceCombId(e);
                  },
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange2('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange2('price', e.target.value),
                })}
              />
              {errors.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '2':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id || ''}
                {...register("service_id", {

                  required: true,
                })}
                onChange={(e) => {
                  handleService(e);
                  handleInputChange2('service_id', e.target.value);
                }}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}
                {...register("sub_service_id", {
                  onChange: (e) => {
                    handleInputChange2('sub_service_id', e.target.value);
                    handleSubServiceCombId(e);
                  },
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Selected Sub Service</Form.Label>
              <Form.Select value={formDataAdd.selected_sub_service}
                {...register("selected_sub_service", {
                  onChange: (e) => handleInputChange2('selected_sub_service', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.selected_sub_service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange2('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>
          </>
        )
      case '3':
        return (
          <>
            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange2('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>


            <Form.Group className="col-12 pb-3">
              <Form.Label>Extra Icon</Form.Label>
              <Form.Control type="file"
                {...register('extra_icon', {
                  required: true,
                  onChange: (e) => { setExtraImage(e.target.files[0]) },
                })}

              />
              {errors.extra_icon && <span className='err_msg'>Please select image</span>}
            </Form.Group>
            <div className='col-12 text-center'>
              <Form.Label>Image Preview</Form.Label>
              <div className='p-3'>

                <img src={extraImage ? URL.createObjectURL(extraImage) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

              </div>
            </div>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange2('price', e.target.value),
                })}
              />
              {errors.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '4':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id || ''}
                {...register("service_id", {

                  required: true,
                })}
                onChange={(e) => {
                  handleService(e);
                  handleInputChange2('service_id', e.target.value);
                }}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}
                {...register("sub_service_id", {
                  onChange: (e) => {
                    handleInputChange2('sub_service_id', e.target.value);
                    handleSubServiceCombId(e);
                  },
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name One</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_name}
                {...register('comb1_name', {
                  required: true,
                  onChange: (e) => handleInputChange2('comb1_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name One</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name Two</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_name}
                {...register('comb2_name', {
                  required: true,
                  onChange: (e) => handleInputChange2('comb2_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name Two</span>}
            </Form.Group>

            <hr></hr>
            <div className='col-12 text-end'>
              <button className="add_btn btn btn-primary" type='button' onClick={handleAddSection}>
                Add
              </button>
            </div>
            {combinations?.map((section) => (
              <div key={section.id} className="row">

                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity One</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Quantity"
                    value={section.comb1_qty}
                    onChange={(e) => handleInputChange1(section.id, 'comb1_qty', e)}

                  />



                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_qty}
                {...register('comb1_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb1_qty', e.target.value),
                })}
              /> */}
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity Two</Form.Label>
                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_qty}
                {...register('comb2_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb2_qty', e.target.value),
                })}
              /> */}

                  <Form.Control
                    type="text"
                    placeholder="Please enter Qunatity"
                    value={section.comb2_qty}

                    onChange={(e) => handleInputChange1(section.id, 'comb2_qty', e)}
                  />
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Please enter Price"
                    value={section.comb_price}

                    onChange={(e) => handleInputChange1(section.id, 'comb_price', e)}
                  />


                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.price}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              /> */}
                  {errors.price && errors.price.type === "required" && <span className='err_msg'>Please Enter Price</span>}
                </Form.Group>
                {combinations.length > 1 && (
                  <>
                    <div className='col-12 text-end'>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveSection(section.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                )}


              </div>
            ))}



            <hr></hr>
          </>
        )
    }
  }
  function getUpdateData(type) {
    switch (type) {
      case '0':
      case '1':
        return (
          <>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id} disabled
                {...register("service_id", {
                  onChange: (e) => handleService(e),
                  required: true,
                })}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''} disabled
                {...register("sub_service_id", {
                  onChange: (e) => {
                    handleInputChange2('sub_service_id', e.target.value);
                    handleSubServiceCombId(e);
                  },
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register1('name', {
                  required: false,
                  onChange: (e) => handleInputChange2('name', e.target.value),

                })}
              />
              {errors1.name && errors1.name.type === "required" && <span className='err_msg'>Please Service Item Name</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register1('price', {
                  required: false,
                  onChange: (e) => handleInputChange2('price', e.target.value),
                })}
              />
              {errors1.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '2':
        return (
          <>


            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id} disabled
                {...register("service_id", {
                  onChange: (e) => handleService(e),
                  required: true,
                })}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''} disabled
                {...register("sub_service_id", {
                  onChange: (e) => {
                    handleInputChange2('sub_service_id', e.target.value);
                    handleSubServiceCombId(e);
                  },
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Selected Sub Service</Form.Label>
              <Form.Select value={formDataAdd.selected_sub_service}
                {...register("selected_sub_service", {
                  onChange: (e) => handleInputChange2('selected_sub_service', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.selected_sub_service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange2('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>

          </>
        )
      case '3':
        return (
          <>
            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register1('name', {
                  required: false,
                  onChange: (e) => handleInputChange2('name', e.target.value),
                })}
              />
              {errors1.name && errors1.name.type === "required" && <span className='err_msg'>Please Service Item Name</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3">
              <Form.Label>Extra Icon</Form.Label>
              <Form.Control type="file"
                {...register1('extra_icon', {
                  required: false,
                  // onChange: (e) => handleInputChange('extra_icon', e.target.files[0]),
                  onChange: (e) => setUpdatedExtra(e.target.files[0]),
                })}

              />
              {errors1.image && <span className='err_msg'>Please select image</span>}
            </Form.Group>
            <div className='col-12 text-center'>
              <Form.Label>Image Preview</Form.Label>
              <div className='p-3'>
                {updatedExtra == "" ?
                  <img src={`/api/assets/extras/${extraImg}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                  :
                  <img src={URL.createObjectURL(updatedExtra)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                }
              </div>
            </div>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register1('price', {
                  required: false,
                  onChange: (e) => handleInputChange2('price', e.target.value),
                })}
              />
              {errors1.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '4':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id} disabled
                {...register("service_id", {
                  onChange: (e) => handleService(e),
                  required: true,
                })}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''} disabled
                {...register("sub_service_id", {
                  onChange: (e) => {
                    handleInputChange2('sub_service_id', e.target.value);
                    handleSubServiceCombId(e);
                  },
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name One</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_name}
                {...register('comb1_name', {
                  required: true,
                  onChange: (e) => handleInputChange2('comb1_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name One</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name Two</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_name}
                {...register('comb2_name', {
                  required: true,
                  onChange: (e) => handleInputChange2('comb2_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name Two</span>}
            </Form.Group>

            <hr></hr>
            <div className='col-12 text-end'>
              <button className="add_btn btn btn-primary" type='button' onClick={handleAddSection}>
                Add
              </button>
            </div>
            {combinations?.map((section) => (
              <div key={section.id} className="row">

                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity One</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Quantity"
                    value={section.comb1_qty}
                    onChange={(e) => handleInputChange1(section.id, 'comb1_qty', e)}

                  />



                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_qty}
                {...register('comb1_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb1_qty', e.target.value),
                })}
              /> */}
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity Two</Form.Label>
                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_qty}
                {...register('comb2_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb2_qty', e.target.value),
                })}
              /> */}

                  <Form.Control
                    type="text"
                    placeholder="Please enter Qunatity"
                    value={section.comb2_qty}

                    onChange={(e) => handleInputChange1(section.id, 'comb2_qty', e)}
                  />
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Please enter Price"
                    value={section.comb_price}

                    onChange={(e) => handleInputChange1(section.id, 'comb_price', e)}
                  />


                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.price}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              /> */}
                  {errors.price && errors.price.type === "required" && <span className='err_msg'>Please Enter Price</span>}
                </Form.Group>
                {combinations.length > 1 && (
                  <>
                    <div className='col-12 text-end'>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveSection(section.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                )}


              </div>
            ))}



            <hr></hr>
          </>
        )
    }
  }


  return (
    <>
      <div className={pending ? 'spinner_d' : 'd-none'}>
        <Spinner animation="border" className='mySpinner' />
      </div>
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        <section class="wrapper">
          <div class="row">
            <div class="col-lg-12">
              <span className='p-3'>Update Sub Service</span>
              <div className='m-3'>
                <Form className='row' onSubmit={handleSubmit4(onSubmit)}>
                  <Form.Group className="col-md-6 pb-5" >
                    <Form.Label>Sub Service Name</Form.Label>



                    <Form.Control type="text" placeholder='Please enter service name'
                      value={formData.name}
                      {...register4('name', {
                        required: false,
                        onChange: (e) => handleInputChange('name', e.target.value)
                      })}
                    />
                    {errors4.name && errors4.name.type === "required" && <span className='err_msg'>Please Enter Service Name</span>}
                  </Form.Group>

                  <hr className='py-5'></hr>

                  <div className='col-md-7'>
                    <Form.Group className=" col-md-12 pb-5">
                      <Form.Label>Service Type</Form.Label>
                      <Form.Control type="text" placeholder='Please enter service Type'
                        value={"Construction & Industrial Cleaning"} disabled
                        {...register4('service_id_one', {
                          required: false,

                        })}
                      />
                      {/* {errors4.name && errors4.name.type === "required" && <span className='err_msg'>Please Enter Service Name</span>} */}

                    </Form.Group>
                  </div>

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Sub Service Type</Form.Label>
                    <Form.Select value={formData.type_one}

                      {...register4('type_one', {
                        required: false,
                        onChange: (e) => handleInputChange('type_one', e.target.value),
                      })}
                    >
                      <option value="">Select</option>
                      <option value="0">Fixed</option>
                      <option value="1">Enquiry</option>
                      <option value="2">Hourly</option>
                      <option value="3">Fixed/Hourly</option>


                    </Form.Select>
                    {errors4.type_one && <span className='err_msg'>Please Select Service Type</span>}
                  </Form.Group>
                  <Form.Group className=" col-md-2 pb-5">
                    <Form.Label>Extras</Form.Label>
                    <Form.Select value={formData.extras_one}
                      {...register4('extras_one', {
                        required: false,
                        onChange: (e) => handleInputChange('extras_one', e.target.value),
                      })}
                    >

                      <option value="0">No</option>
                      <option value="1">Yes</option>


                    </Form.Select>
                    {errors4.extras_one && <span className='err_msg'>Please select extra</span>}
                  </Form.Group>


                  {getServiceData(formData.type_one, 'type_one')}


                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Regular Cleaning</Form.Label>
                    <Form.Select value={formData.regular_service_one}
                      {...register4('regular_service_one', {
                        required: false,
                        onChange: (e) => handleInputChange('regular_service_one', e.target.value),
                      })}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>

                    </Form.Select>
                    {errors4.regular_service_one && <span className='err_msg'>Please select Regular Cleaning</span>}
                  </Form.Group>

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Select value={formData.prop_typeask_one}
                      {...register4('prop_typeask_one', {
                        required: false,
                        onChange: (e) => handleInputChange('prop_typeask_one', e.target.value),
                      })}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>

                    </Form.Select>
                    {errors4.prop_typeask_one && <span className='err_msg'>Please select Property Type</span>}
                  </Form.Group>

                  <Form.Group className="col-md-3 pb-5" >
                    <Form.Label>GST(%)</Form.Label>
                    <Form.Control type="number"
                      value={formData.gst_one || ''}

                      {...register4('gst_one', {
                        required: false,
                        onChange: (e) => handleInputChange('gst_one', e.target.value),
                      })}

                    />
                    {errors4.gst_one && <span className='err_msg'>Please enter GST(%)</span>}
                  </Form.Group>


                  <div className='col-lg-12 pb-5'>
                    <Form.Label>Sub Service Content</Form.Label>
                    <TextEditor data={formData.content_one || ''} setUpdatedContent={(newContent) => handleEditorChange('content_one', newContent)} />
                  </div>

                  <Form.Group className="col-md-6 pb-5">
                    <Form.Label>Service Image</Form.Label>
                    <Form.Control type="file"
                      {...register4('image_one', {
                        required: false,
                        onChange: (e) => { handleInputChange('image_one', e.target.files[0]); setImage(e.target.files[0]) },
                      })}

                    />
                    {errors4.image_one && <span className='err_msg'>Please select image</span>}
                  </Form.Group>
                  <div className='col-lg-6 text-center'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      <img src={formData.image_one ? URL.createObjectURL(formData.image_one) : allImage.image_one ? `/api/assets/subservice/${allImage.image_one}` : `${NoDataFound}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                    </div>
                  </div>

                  <hr className='py-5'></hr>

                  <div className='col-md-7'>
                    <Form.Group className=" col-md-12 pb-5">
                      <Form.Label>Service Type</Form.Label>
                      <Form.Control type="text" placeholder='Please enter service Type'
                        value={"Residential Cleaning"} disabled
                        {...register4('service_id_two', {
                          required: false,
                        })}
                      />
                      {/* {errors4.name && errors4.name.type === "required" && <span className='err_msg'>Please Enter Service Name</span>} */}
                    </Form.Group>
                  </div>

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Sub Service Type</Form.Label>
                    <Form.Select value={formData.type_two}

                      {...register4('type_two', {
                        required: false,
                        onChange: (e) => handleInputChange('type_two', e.target.value),
                      })}
                    >
                      <option value="">Select</option>
                      <option value="0">Fixed</option>
                      <option value="1">Enquiry</option>
                      <option value="2">Hourly</option>
                      <option value="3">Fixed/Hourly</option>


                    </Form.Select>
                    {errors4.type_two && <span className='err_msg'>Please Select Service Type</span>}
                  </Form.Group>
                  <Form.Group className=" col-md-2 pb-5">
                    <Form.Label>Extras</Form.Label>
                    <Form.Select value={formData.extras_two}
                      {...register4('extras_two', {
                        required: false,
                        onChange: (e) => handleInputChange('extras_two', e.target.value),
                      })}
                    >

                      <option value="0">No</option>
                      <option value="1">Yes</option>


                    </Form.Select>
                    {errors4.extras_two && <span className='err_msg'>Please select extra</span>}
                  </Form.Group>

                  {getServiceData(formData.type_two, 'type_two')}

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Regular Cleaning</Form.Label>
                    <Form.Select value={formData.regular_service_two}
                      {...register4('regular_service_two', {
                        required: false,
                        onChange: (e) => handleInputChange('regular_service_two', e.target.value),
                      })}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>

                    </Form.Select>
                    {errors4.regular_service_two && <span className='err_msg'>Please select Regular Cleaning</span>}
                  </Form.Group>

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Select value={formData.prop_typeask_two}
                      {...register4('prop_typeask_two', {
                        required: false,
                        onChange: (e) => handleInputChange('prop_typeask_two', e.target.value),
                      })}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>

                    </Form.Select>
                    {errors4.prop_typeask_two && <span className='err_msg'>Please select Property Type</span>}
                  </Form.Group>

                  <Form.Group className="col-md-3 pb-5" >
                    <Form.Label>GST(%)</Form.Label>
                    <Form.Control type="number"
                      value={formData.gst_two}

                      {...register4('gst_two', {
                        required: false,
                        onChange: (e) => handleInputChange('gst_two', e.target.value),
                      })}

                    />
                    {errors4.gst_two && <span className='err_msg'>Please enter GST(%)</span>}
                  </Form.Group>


                  <div className='col-lg-12 pb-5'>
                    <Form.Label>Sub Service Content</Form.Label>
                    <TextEditor data={formData.content_two} setUpdatedContent={(newContent) => handleEditorChange('content_two', newContent)} />
                  </div>

                  <Form.Group className="col-md-6 pb-5">
                    <Form.Label>Service Image</Form.Label>
                    <Form.Control type="file"
                      {...register4('image_two', {
                        required: false,
                        onChange: (e) => { handleInputChange('image_two', e.target.files[0]); setImage(e.target.files[0]) },
                      })}

                    />
                    {errors4.image_two && <span className='err_msg'>Please select image</span>}
                  </Form.Group>
                  <div className='col-lg-6 text-center'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>
                      <img src={formData.image_two ? URL.createObjectURL(formData.image_two) : allImage.image_two ? `/api/assets/subservice/${allImage.image_two}` : `${NoDataFound}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                    </div>
                  </div>

                  <hr className='py-5'></hr>

                  <div className='col-md-7'>
                    <Form.Group className=" col-md-12 pb-5">
                      <Form.Label>Service Type</Form.Label>
                      <Form.Control type="text" placeholder='Please enter service Type'
                        value={"Commercial Cleaning"} disabled
                        {...register4('service_id_three', {
                          required: false,
                        })}
                      />
                      {/* {errors4.name && errors4.name.type === "required" && <span className='err_msg'>Please Enter Service Name</span>} */}
                    </Form.Group>
                  </div>

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Sub Service Type</Form.Label>
                    <Form.Select value={formData.type_three}

                      {...register4('type_three', {
                        required: false,
                        onChange: (e) => handleInputChange('type_three', e.target.value),
                      })}
                    >
                      <option value="">Select</option>
                      <option value="0">Fixed</option>
                      <option value="1">Enquiry</option>
                      <option value="2">Hourly</option>
                      <option value="3">Fixed/Hourly</option>


                    </Form.Select>
                    {errors4.type_three && <span className='err_msg'>Please Select Service Type</span>}
                  </Form.Group>
                  <Form.Group className=" col-md-2 pb-5">
                    <Form.Label>Extras</Form.Label>
                    <Form.Select value={formData.extras_three}
                      {...register4('extras_three', {
                        required: false,
                        onChange: (e) => handleInputChange('extras_three', e.target.value),
                      })}
                    >

                      <option value="0">No</option>
                      <option value="1">Yes</option>


                    </Form.Select>
                    {errors4.extras_three && <span className='err_msg'>Please select extra</span>}
                  </Form.Group>

                  {getServiceData(formData.type_three, 'type_three')}

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Regular Cleaning</Form.Label>
                    <Form.Select value={formData.regular_service_three}
                      {...register4('regular_service_three', {
                        required: false,
                        onChange: (e) => handleInputChange('regular_service_three', e.target.value),
                      })}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>

                    </Form.Select>
                    {errors4.regular_service_three && <span className='err_msg'>Please select Regular Cleaning</span>}
                  </Form.Group>

                  <Form.Group className=" col-md-3 pb-5">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Select value={formData.prop_typeask_three}
                      {...register4('prop_typeask_three', {
                        required: false,
                        onChange: (e) => handleInputChange('prop_typeask_three', e.target.value),
                      })}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>

                    </Form.Select>
                    {errors4.prop_typeask_three && <span className='err_msg'>Please select Property Type</span>}
                  </Form.Group>

                  <Form.Group className="col-md-3 pb-5" >
                    <Form.Label>GST(%)</Form.Label>
                    <Form.Control type="number"
                      value={formData.gst_three}

                      {...register4('gst_three', {
                        required: false,
                        onChange: (e) => handleInputChange('gst_three', e.target.value),
                      })}

                    />
                    {errors4.gst_three && <span className='err_msg'>Please enter GST(%)</span>}
                  </Form.Group>


                  <div className='col-lg-12 pb-5'>
                    <Form.Label>Sub Service Content</Form.Label>
                    <TextEditor data={formData.content_three || ''} setUpdatedContent={(newContent) => handleEditorChange('content_three', newContent)} />
                  </div>

                  <Form.Group className="col-md-6 pb-5">
                    <Form.Label>Service Image</Form.Label>
                    <Form.Control type="file"
                      {...register4('image_three', {
                        required: false,
                        onChange: (e) => { handleInputChange('image_three', e.target.files[0]); setImage(e.target.files[0]) },
                      })}

                    />
                    {errors4.image_three && <span className='err_msg'>Please select image</span>}
                  </Form.Group>
                  <div className='col-lg-6 text-center'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      <img src={formData.image_three ? URL.createObjectURL(formData.image_three) : allImage.image_three ? `/api/assets/subservice/${allImage.image_three}` : `${NoDataFound}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                    </div>
                  </div>

                  <hr className='py-4'></hr>

                  <Form.Group className="col-md-7 pb-5" >
                    <Form.Label>Banner Text</Form.Label>
                    <Form.Control type="text"
                      value={formData.banner_text || ''}

                      {...register4('banner_text', {
                        required: false,
                        onChange: (e) => handleInputChange('banner_text', e.target.value),
                      })}

                    />
                    {errors4.banner_text && <span className='err_msg'>Please enter Banner Text </span>}
                  </Form.Group>

                  <Form.Group className="col-md-6 pb-5">
                    <Form.Label>Banner Image</Form.Label>
                    <Form.Control type="file"
                      {...register4('banner_image', {
                        required: false,
                        onChange: (e) => { handleInputChange('banner_image', e.target.files[0]); setBannerImage(e.target.files[0]) },
                      })}


                    />
                    {errors4.banner_image && <span className='err_msg'>Please select Banner Image </span>}
                  </Form.Group>
                  <div className='col-lg-6 text-center'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>


                      <img src={formData.banner_image ? URL.createObjectURL(formData.banner_image) : allImage.banner_image ? `/api/assets/subservice/${allImage.banner_image}` : `${NoDataFound}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                    </div>
                  </div>

                  <Form.Group className="col-md-6 pb-5">
                    <Form.Label>Canonical</Form.Label>
                    <Form.Control type="text"

                      value={formData.canonical || ''}
                      {...register4('canonical', {
                        required: false,
                        onChange: (e) => handleInputChange('canonical', e.target.value),
                      })}

                    />

                  </Form.Group>
                  <Form.Group className="col-md-6 pb-5" >
                    <Form.Label>SEO Title</Form.Label>
                    <Form.Control type="text"
                      value={formData.seo_title || ''}
                      {...register4('seo_title', {
                        required: false,
                        onChange: (e) => handleInputChange('seo_title', e.target.value),
                      })}

                    />
                    {errors.seo_title && <span className='err_msg'>Please enter Seo Title </span>}
                  </Form.Group>
                  <Form.Group className="col-md-6 pb-5" >
                    <Form.Label>SEO Keywords</Form.Label>
                    <Form.Control type="text"
                      value={formData.seo_keywords || ''}
                      {...register4('seo_keywords', {
                        required: false,
                        onChange: (e) => handleInputChange('seo_keywords', e.target.value),
                      })}
                    // onChange={(e) => setSeoKeyword(e.target.value)}

                    />
                  </Form.Group>
                  <Form.Group className=" col-md-6 pb-5">
                    <Form.Label>SEO Follow</Form.Label>
                    <Form.Select value={formData.seo_follow || ''} onChange={(e) => handleInputChange('seo_follow', e.target.value)}>
                      <option value="1">Index Follow</option>
                      <option value="2">Index No-follow</option>
                      <option value="3">No-index Follow</option>
                      <option value="4">No-index No-follow</option>
                    </Form.Select>

                  </Form.Group>
                  <Form.Group className="col-md-6 pb-5">
                    <Form.Label>SEO Script</Form.Label>
                    <Form.Control type="text"
                      value={formData.seo_script || ''}
                      onChange={(e) => handleInputChange('seo_script', e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="col-md-12 pb-5">
                    <Form.Label>SEO Description</Form.Label>
                    <Form.Control as="textarea" rows={3} name="seo_description"
                      value={formData.seo_description || ''}
                      onChange={(e) => handleInputChange('seo_description', e.target.value)}
                    />
                  </Form.Group>

                  <div className='text-center'>
                    <Button disabled={pending ? true : false} className='Add_btn_' type='submit'>
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>


            </div>
          </div>

          <div className="main">
            <div className="addcareer p-2">
              {localStorage.getItem("addAuth") == 0 ?
                <Button className="Add_btn_" onClick={() => handleShow()}>
                  Add Sub Service Item
                </Button>
                : null}
            </div>

            <DataTables data={data} columns={tableData.columns} title="Sub Service Items" />
            {/* </DataTableExtensions> */}
          </div>
        </section>


        {/* add item */}
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton className='adminpass'>
            <Modal.Title>Add Sub Service Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='row' onSubmit={handleSubmit2(onSubmit2)}>

              <Form.Group className=" col-12 pb-3">
                <Form.Label>Sub Service Item Type</Form.Label>
                <Form.Select value={subServiceListType}

                  {...register('serviceType', {
                    required: true,
                    onChange: (e) => setSubServiceListType(e.target.value),
                  })}

                // onChange={(e) => setSubServiceType(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="0">Quantity</option>
                  <option value="1">Yes/No</option>
                  <option value="2">Yes/No with Item</option>
                  <option value="3">Extra</option>
                  <option value="4">Combination</option>
                </Form.Select>
                {errors.serviceType && <span className='err_msg'>Please Select Service Type</span>}
              </Form.Group>

              {getItemData(subServiceListType)}


              <div className="_add_float mt-2">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="_close_btn"
                >
                  Close
                </Button>
                <Button className="Add_btn_" type="submit">Submit</Button>
              </div>
            </Form>
          </Modal.Body>

        </Modal>

        {/* update Item */}
        <Modal show={showUpdate} onHide={handleUpdateClose} size="lg">
          <Modal.Header closeButton className='adminpass'>
            <Modal.Title>Update Sub Service Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='row' onSubmit={handleSubmit1(onUpdate)}>

              <Form.Group className=" col-12 pb-3">
                <Form.Label>Sub Service Item Type</Form.Label>
                <Form.Select value={updateSelectedType}
                  disabled
                  {...register1('serviceType', {
                    required: false,
                    onChange: (e) => setUpdateSelectedType(e.target.value),
                  })}

                // onChange={(e) => setSubServiceType(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="0">Quantity</option>
                  <option value="1">Yes/No</option>
                  <option value="2">Yes/No with Item</option>
                  <option value="3">Extra</option>
                  <option value="4">Combination</option>
                </Form.Select>
                {errors1.serviceType && <span className='err_msg'>Please Select Service Type</span>}
              </Form.Group>

              {getUpdateData(updateSelectedType)}


              <div className="_add_float mt-2">
                <Button
                  variant="secondary"
                  onClick={handleUpdateClose}
                  className="_close_btn"
                >
                  Close
                </Button>
                <Button className="Add_btn_" type="submit">Update</Button>
              </div>
            </Form>
          </Modal.Body>

        </Modal>


        {/* delete */}
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton className='adminpass'>
            <Modal.Title>Delete Sub Service List Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
              <h3>Are you sure to delete this Sub Service List Item</h3>
            </div>

            <div className="_add_float mt-2">
              <Button
                variant="secondary"
                onClick={handleCloseDelete}
                className="_close_btn"
              >
                Close
              </Button>
              <Button variant='danger' onClick={() => handleDelete()}>Delete</Button>
            </div>

          </Modal.Body>


        </Modal>
      </section>

    </>
  )
}

export default UpdateSubService;