import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import TextEditor from '../Editor/TextEditor';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Spinner from 'react-bootstrap/Spinner';

const AboutAdmin = () => {


    const [aboutData, setAboutData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [pending, setPending] = useState(true);


    const actualData = aboutData?.map((data) => data.content).toString();


    const notify = () => toast.info("Edit SuccessFully", { position: "top-center" });

    const getData = () => {
        axios.get('/api/getAdminAbout').then((response) => {
            setAboutData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    useEffect(() => {
        getData();

    }, [])


    const handleUpdate = () => {

        axios.get('/api/updateAbout', { content: updatedContent, }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
            }

        }).catch((err) => {

            console.log(err);
        })
    }

    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | About</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>


            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>About</span>
                            <div className='p-3'>
                                <TextEditor data={actualData} setUpdatedContent={setUpdatedContent} />
                            </div>
                            <section class="card m-3">


                            </section>
                            <div>
                                {localStorage.getItem("editAuth") == 0 ?
                                    <Button className='Add_btn_' onClick={handleUpdate}>
                                        Update
                                    </Button>
                                    : null}

                            </div>
                        </div>
                    </div>
                </section>
            </section>

        </>
    )
}

export default AboutAdmin;