import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { HiRefresh } from 'react-icons/hi';
import { FaShare, FaEdit, FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const SubServiceAdmin = () => {


    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get('/api/getAdminSubService').then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        })
    }

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        setData(newData?.filter(data => data.status == 0));

    }, [newData]);

    const handleShow = (id) => {
        setShow(true);
        setDeleteId(id);
    };

    const handleClose = () => {
        setShow(false);
        setDeleteId('');

    };

    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.get('/api/subServiceStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                window.location.reload(false);

            }
        }).catch((err) => {

            console.log(err);
        })
    }

    const deleteSuccess = () =>
        toast.info("Sub Service Deleted Successfully", { position: "top-center" });

    const handleServiceDelete = () => {

        axios
            .post(
                "/deleteSubService",
                {
                    id: deleteId
                },
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    handleClose();
                    deleteSuccess();
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }


    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }

    const tableData = {
        columns: [
            {
                name: '#',
                width: '50px',
                cell: (row, index) => index + 1
            },
            {
                name: "Service Name",
                width: '200px',
                selector: "service_id",
                sortable: true
            },
            {
                name: "Name",
                selector: "name",
                width: '300px',
                sortable: true
            },
            {
                name: "Image",
                selector: (row) => <img style={{ width: '50px', height: '50px', padding: '10px' }} src={`assets/subservice/${row.image}`} />,
                width: '100px',
                sortable: true
            },

            {
                name: "Status",
                // width: '200px',
                selector: row => <select className=" col-md-4 select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
                    value={row.status}
                    id={row.id}
                    onChange={(e) => handleStatus(e)}
                >
                    <option value="0">Active</option>
                    <option value="1">In-Active</option>

                </select>,
                center: true,
                // selector: (row) => ,

            },
            {
                name: "Action",
                width: '100px',
                cell: row => <div className='d-flex' style={{ gap: '10px' }}>
                    {localStorage.getItem("editAuth") == 0 ? <Link to={`/admin/subservice/updatesubservice/${row.id}`} className={row.status == 1 ? "d-none" : "d-block"}><Button className='_edit_icons'><FaEdit /></Button></Link> : null}
                    {localStorage.getItem("deleteAuth") == 0 ? <Button className='_delete_btn_' onClick={() => handleShow(row.id)}><FaTrashAlt /></Button> : null} </div>,
                right: true

            },

        ],

    };


    return (
        <>


            <Helmet>

                <title>Blue Cleaning Group | Sub-services</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Sub Services</span>
                            <section className="card m-3">


                                <div className="selectoption justify-content-between align-items-center mt-4">
                                    <div>
                                        <strong >Status :-</strong>
                                        <select className="status filter"

                                            onChange={(e) => filterByStatus(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="0" selected>Active</option>
                                            <option value="1">In-Active</option>
                                        </select>
                                    </div>
                                    {localStorage.getItem("addAuth") == 0 ?
                                        <Link to='/admin/subservice/addsubservice'><Button className="Add_btn_ m-3">
                                            Add Sub-Service
                                        </Button></Link> : null}

                                </div>

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Sub Service" />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton className='adminpass'>
                                <Modal.Title>Delete Sub Service</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                                    <h3>Are you sure to delete this Sub Service</h3>
                                </div>

                                <div className="_add_float mt-2">
                                    <Button
                                        variant="secondary"
                                        onClick={handleClose}
                                        className="_close_btn"
                                    >
                                        Close
                                    </Button>
                                    <Button variant='danger' onClick={() => handleServiceDelete()}>Delete</Button>
                                </div>

                            </Modal.Body>


                        </Modal>
                    </div>
                </section>
            </section>
        </>
    )
}

export default SubServiceAdmin;