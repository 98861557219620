import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const YourComponent = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    // Make the AJAX call to retrieve the product list when the component mounts
    // You can use the fetch or axios library to make the AJAX call in React.
    // For simplicity, I'll use a dummy product list here.
    const dummyProductList = [
      { id: 1, name: 'Product 1' },
      { id: 2, name: 'Product 2' },
      // Add more products as needed
    ];

    // Set the initial section with a single row
    setSections([
      {
        id: Date.now(),
        product_id: '',
        price: '',
        quantity: '',
        fat: '',
        clr: '',
        gst: '',
        
      },
    ]);
  }, []);

  const handleAddSection = () => {
    // Create a new section with a unique ID
    const newSection = {
      id: Date.now(),
      product_id: '',
      price: '',
      quantity: '',
      fat: '',
      clr: '',
      gst: '',
     
    };

    // Add the new section to the existing sections
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleRemoveSection = (idToRemove) => {
    // Remove the section with the provided ID from the sections array
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== idToRemove)
    );
  };

  const handleInputChange = (id, field, event) => {
    // Update the state with the new value for the specified field in the section with the provided ID
    const { value } = event.target;
    setSections((prevSections) =>
      prevSections?.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      )
    );
  };

  return (
    <div style={{ marginLeft: '300px' }}>
      {/* Render existing sections */}
      {sections?.map((section) => (
        <div key={section.id} className="row">
          {/* ... Your JSX code for the repeated section ... */}
          <Form.Group className="col-4 pb-3">
            <Form.Label>Combination Quantity One</Form.Label>
            <Form.Control
              type="text"
              placeholder="Please enter name"
              value={section.comb1_qty}
              onChange={(e) => handleInputChange(section.id, 'comb1_qty', e)}
            />
          </Form.Group>
          <Form.Group className="col-4 pb-3">
            <Form.Label>Combination Quantity Two</Form.Label>
            <Form.Control
              type="text"
              placeholder="Please enter name"
              value={section.comb2_qty}
              onChange={(e) => handleInputChange(section.id, 'comb2_qty', e)}
            />
          </Form.Group>

          {/* Add more form fields for other properties as needed */}
          {/* ... */}
          <div className="col-12 text-end">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveSection(section.id)}
            >
              Remove
            </button>
          </div>
          <hr />
        </div>
      ))}

      {/* Add button to add new sections */}
      <button className="add_btn btn btn-primary" onClick={handleAddSection}>
        Add Section
      </button>
    </div>
  );
};

export default YourComponent;
