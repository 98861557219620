import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProcessImg2 from '../assets/Home Page Assets/Mask Group 158-min.png';
import ProcessImg3 from '../assets/Home Page Assets/Mask Group 159-min.png';
import BookUs from '../common/BookUs';
import { Tabs, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaTimes } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { motion } from 'framer-motion';
import { fixedContainer, fadeIn, textVariants } from '../common/motion/motion';

const Gallery = () => {


    const [banner, setBanner] = useState([]);
    const bannerImg = banner?.map((banner) => banner.image)
    const bannerContent = banner?.map((banner) => banner.content);
    const [all, setAll] = useState([]);
    const [galleryRes, setGalleryRes] = useState([]);
    const [galleryCom, setGalleryCom] = useState([]);
    const [galleryCon, setGalleryCon] = useState([]);
    const [myspinner, setMyspinner] = useState(false);
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');

    const getAllData = () => {

        axios.get('/api/getSeo/3').then((response) => {
            setSeoContent(response.data.data);

        }).catch((err) => {
            console.log(err);
        })

        axios.get('/api/getBanner/3'
        ).then((response) => {
            setBanner(response.data.data);

        }).catch((err) => {
            console.log(err);
        })

        axios.get('/api/getGallery/1'
        ).then((response) => {
            if (response.status === 200) {
                setGalleryRes(response.data.data);
            }

        }).catch((err) => {
            console.log(err);
        })

        axios.get('/api/getGallery/2'
        ).then((response) => {
            if (response.status === 200) {
                setGalleryCon(response.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })

        axios.get('/api/getGallery/3'
        ).then((response) => {
            if (response.status === 200) {
                setMyspinner(false);
                setGalleryCom(response.data.data);

            }
        }).catch((err) => {
            console.log(err);
            setMyspinner(false);
        })

    }



    const [modalShow, setModalShow] = useState(false);
    const [srcs, setSrcs] = useState("");

    const openModel = (e) => {
        setModalShow(true);
        setSrcs(e.target.src);
    }

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllData();
        setMyspinner(true);
        Aos.init({
            duration: 1000,
        })
    }, []);




    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta name="keywords" content={seoKeyword} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="hweindia" />
                <meta property="og:url" content={canonical} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta name="robots" content={robot} />
                <script>{seoScript}</script>

            </Helmet>

            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
                <section>
                    <motion.div
                        variants={fadeIn}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        className='banner' style={{ backgroundImage: `url(/api/assets/banner/${bannerImg})` }}>
                        <div className='container-fluid' style={{ position: 'relative' }}>
                            <div className='content_div' style={{ top: '120px' }}>

                                <motion.h3
                                    variants={textVariants('up', 0.1)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    style={{ color: '#fff' }}>{bannerContent}</motion.h3>

                            </div>
                        </div>
                    </motion.div>
                </section>

                {galleryCom == undefined && galleryCon == undefined && galleryRes == undefined ? <><div className='d-flex justify-content-center align-items-center' style={{height: '300px'}}><h3 className='mt-5'>No data found</h3></div></> :

                    <section className='our_work-s'>
                        <div className='our_works' style={{ paddingTop: "3rem" }}>
                            <center className="mb-4">
                                <motion.h5
                                    variants={textVariants('up', 0.1)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    style={{ justifyContent: 'center' }}>Our Work</motion.h5>
                                <motion.h3
                                    variants={textVariants('up', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    className='title'>Our Portfolio</motion.h3>
                            </center>
                            <div className='bg_imgDiv'>
                                <motion.img
                                    variants={textVariants('up', 0.1)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    className='img1' src={ProcessImg3} />
                                <motion.img
                                    variants={textVariants('up', 0.1)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    className='img2' src={ProcessImg2} />
                            </div>
                            <div className='container-fluid'>
                                <center>
                                    <Tabs
                                        defaultActiveKey="Residential Cleaning"
                                        id="justify-tab-example"
                                        className="tab_btn"
                                        justify
                                    >
                                        {/* <Tab eventKey="All" title="All" >
                                        <div className='mt-5'>
                                            <main className='gallery_imgs'>
                                                {galleryRes?.concat(galleryCon).concat(galleryCom).map((gallery, index) => {
                                                    return (
                                                        <motion.article
                                                            variants={textVariants('left', index / 10)}
                                                            initial="hidden"
                                                            whileInView="show"
                                                            viewport={{ once: true, amount: 0.1 }}
                                                            key={gallery.id}>
                                                            <img src={`/api/assets/gallery/${gallery.image}`} onClick={openModel} />
                                                        </motion.article>
                                                    )
                                                })}
                                            </main>
                                        </div>
                                    </Tab> */}
                                        <Tab eventKey="Residential Cleaning" title="Residential Cleaning" >
                                            <div className='mt-5'>
                                                <main className='gallery_imgs'>
                                                    {galleryRes?.map((gallery, index) => {
                                                        return (
                                                            <motion.article
                                                                variants={textVariants('left', index / 10)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }} key={gallery.id}>
                                                                <img src={`/api/assets/gallery/${gallery.image}`} onClick={openModel} />
                                                            </motion.article>
                                                        )
                                                    })}
                                                </main>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="profile" title="Construction Cleaning">
                                            <div className='mt-5'>
                                                <main className='gallery_imgs'>
                                                    {galleryCon?.map((gallery, index) => {
                                                        return (
                                                            <motion.article
                                                                variants={textVariants('left', index / 10)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }} key={gallery.id}>
                                                                <img src={`/api/assets/gallery/${gallery.image}`} onClick={openModel} />
                                                            </motion.article>
                                                        )
                                                    })}

                                                </main>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="longer-tab" title="Commercial Cleaning">
                                            <div className='mt-5'>
                                                <main className='gallery_imgs'>
                                                    {galleryCom?.map((gallery, index) => {
                                                        return (
                                                            <motion.article
                                                                variants={textVariants('left', index / 10)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }} key={gallery.id}>
                                                                <img src={`/api/assets/gallery/${gallery.image}`} onClick={openModel} />
                                                            </motion.article>
                                                        )
                                                    })}

                                                </main>
                                            </div>
                                        </Tab>
                                    </Tabs>


                                </center>
                            </div>
                        </div>
                    </section>

                }

                {/* <BookUs /> */}
            </div >

            {/* modal */}

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                // size="md"

                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='p-1'>
                    <FaTimes className='close_btn' onClick={() => setModalShow(false)} />
                    <img className='w-100' src={srcs} />
                </Modal.Body>

            </Modal>
        </>
    )
}


export default Gallery
